import React from "react";
import axios from "axios";
import moment from "moment";
import swal from '@sweetalert/with-react';
import date from 'date-and-time'

import GlobalFunctions from '../controllers/globalFunctions';


//const API_MAIN_URL=`https://rosterapi.tarisa.com.au/engage/`;
//const API_MAIN_URL=`http://localhost:4001`

class Controllers extends React.Component {
   
    
      calculateDuration123 = (event) =>{
        var startTime = event.startTime&&event.endTime?date.parse(event.startTime, 'HH:mm:ss A', true):null;
        var endTime =event.startTime&&event.endTime? date.parse(event.endTime, 'HH:mm:ss A', true):null;
        var duration = event.startTime&&event.endTime?date.subtract(endTime, startTime).toHours():0;
        duration = duration<0?24+duration:duration;
        this.setState({ calculatedDuration: duration });
        return duration;
      }

      getClients = () => {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          this.setState({ clients: client.data });
          client.data.forEach(element=>{
            this.state.clientSelectList.push({
              label : element.clientname,
              value : element.id
            })
          })
        })
    
      }
    
       calculateWeekRange = () =>{
        let now = moment();
        const dayName = moment(now).format('dddd');
        var day = Number(now.weekday()) - 1
        var start = now.subtract(day, 'days').format('MM-DD-YYYY');
        var end = now.add(6, 'days').format('MM-DD-YYYY');
      
        if(dayName=='Sunday'){
          end = moment(start).format('MM-DD-YYYY');
          end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
          start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
        }
        return {start: start, end: end }
      }
    
      getNextWeek = () => {
        var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
        start = moment(start).add(1, 'days').format('MM-DD-YYYY');
        var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
        return {start: start, end: end }
      }
    
      getPreviousWeek = () => {
        var end = moment(this.state.weekStart).format('MM-DD-YYYY');
        end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
        var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');
        return {start: start, end: end }
      }

      //AN HTTP REQUEST TO THE getAllShifts-endpoint
   getShifts = (event) =>{
    this.setState({ loadingData: true })
    var shiftsList = [];
    var sortedShiftList = [];
    var openShiftObject;
    var staffList = [];
    var temp = [];
    var finalData = [];
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(shifts => {
      this.setState({ shifts: shifts.data });
      //FETCHING ALL STAFF FROM API
      url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?weekStart=${event.start}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(openShift => {
          shiftsList = shifts.data;
          staffList = staff.data;
          
          //SELECTING MANAGERS FOR FILTERING PURPOSE
          this.setState({managersSeleectList :  []});
          this.state.managersSeleectList.push({label : 'All Managers', value : 0})
          staffList.forEach(element=>{
            if (element.access_level==3) {
              this.state.managersSeleectList.push({
                label : element.lastname+' '+element.firstname, 
                value : element.id
              })
            }
          })
          this.setState({ staffList: staffList });
          //ADDING OPEN SHIFTS FOR DISPLAY
          
          openShiftObject = { staffId: -1, staffName: 'Open Shifts', shifts: openShift.data };
          //GROUPING SHIFTS ACCORDING TO STAFF
          staffList.forEach(staffElement => {
            shiftsList.forEach(shiftElement => {
              if (staffElement.id == shiftElement.staffId) {
                temp.push(shiftElement);
              }
            });
            finalData.push({ staffId: staffElement.id, propertId : staffElement.propertId, managerId : staffElement.managerId, staffName: staffElement.lastname + ' ' + staffElement.firstname, availability: staffElement.availability, shifts: temp });
            temp = [];
          });
          finalData = finalData.sort(function (a, b) {
            if (a.staffName < b.staffName) {
              return -1;
            }
            if (a.staffName > b.staffName) {
              return 1;
            }
            return 0;
          })
          sortedShiftList.push(openShiftObject);
          finalData.forEach(element => {
            sortedShiftList.push(element);
          })
          this.setState({ DatabaseData: sortedShiftList, shiftsList: sortedShiftList, loadingData: false })
          this.countShifts(finalData);
          return finalData
        }).catch(error => {

        })
      }).catch(error => {

      })
    }).catch(error => {

    })
  }

   
    
      checkStaffAvailability =  (event) =>{
        var url = `${GlobalFunctions.extractToken().API_URL}/shifts/checkStaffAvailability?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&date=${event.date}&staffId=${event.staffId}`
        axios.get(url).then(result =>{
          this.setState({isStaffAvailable:result.data});
        }).catch(error =>{
          swal("Error when checking for staff availability!", " ", "warning");
          console.log(error);
        })
      }
    
    // ...etc
  }

  export default Controllers;