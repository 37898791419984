import React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import './styles/main.css';
import ReactTable from 'react-table-6'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import 'react-table-6/react-table.css'
import { array, element } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './styles/navbar.css';
import Modal from "react-bootstrap/Modal";
import moment, { relativeTimeThreshold, duration } from 'moment'
import swal from '@sweetalert/with-react';
import date from "date-and-time";
import Spinner from 'react-bootstrap/Spinner'
import { white } from 'color-name';
import { formatDate } from 'tough-cookie';
import { Popover, OverlayTrigger } from "react-bootstrap"
import { Chart } from 'react-charts'
import GlobalFunctions from './controllers/globalFunctions';
import Select from 'react-select';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import DatePicker from "react-datepicker";
import { BsEyeFill } from "react-icons/bs";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import "react-datepicker/dist/react-datepicker.css";
import { parseJSON } from 'date-fns';
import Controllers from './controllers/schedules';


//IMPORTING VIEWS;
import Schedules from './views/schedules'
import Dashboard from './views/dashboad'
import Timesheets from './views/timeSheets'
import Invoices from './views/customerInvoices'

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftData : [],
      token : ''
    }
  }
 
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" render={(props) => <Dashboard token={this.state.token}/>} exact={true} />
          <Route path="/schedule" render={(props) => <Schedules data={this.state.shiftData}/>}/>
          <Route path="/timesheets" render={(props) => <Timesheets myProps={`englon`}/>} />
          <Route path="/invoicing" render={(props) => <Invoices myProps={`englon`}/>} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    )
  }
}

//ERROR PAGE COMPONENT
class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        Error page
      </div>
    )
  }
}

{/* NAVBAR COMPONENT */ }
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      ACCESS_LEVEL : 0
    };     
  }
  componentDidMount() {
    GlobalFunctions.requestUserFromAPI().then(res=>{
      this.setState({ACCESS_LEVEL : res.ACCESS_LEVEL});
      GlobalFunctions.extractToken();
    });
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark bg-warning">
          <Link className="navbar-brand" to="/">Tarisa Roster</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarsExample04">
            <ul className="navbar-nav mr-auto">
              {
                true ? <li className="nav-item">
                  <a className="nav-link" href={GlobalFunctions.extractToken().COMPANY_URL}>Home</a>
                </li> : ""
              }
              {
                true ? <li className="nav-item">
                  <Link className="nav-link" to={`/?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&API_URL=${GlobalFunctions.extractToken().API_URL}&COMPANY_URL=${GlobalFunctions.extractToken().COMPANY_URL}&isXeroConnected=${GlobalFunctions.extractToken().isXeroConnected}`}>Dashboard</Link>
                </li> : ""
              }
              {
                this.state.ACCESS_LEVEL ==5||this.state.ACCESS_LEVEL<=3? <li className="nav-item">
                  <Link className="nav-link" to={`/schedule/?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&API_URL=${GlobalFunctions.extractToken().API_URL}&COMPANY_URL=${GlobalFunctions.extractToken().COMPANY_URL}&isXeroConnected=${GlobalFunctions.extractToken().isXeroConnected}`} >Schedule</Link>
                </li> : ""
              }

              {
                this.state.ACCESS_LEVEL ==5||this.state.ACCESS_LEVEL<=3 ? <li className="nav-item">
                  <Link className="nav-link" to={`/timesheets/?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&API_URL=${GlobalFunctions.extractToken().API_URL}&COMPANY_URL=${GlobalFunctions.extractToken().COMPANY_URL}&isXeroConnected=${GlobalFunctions.extractToken().isXeroConnected}`} >Timesheets</Link>
                </li> : ""
              }
              {
                this.state.ACCESS_LEVEL ==5||this.state.ACCESS_LEVEL<=3 ? <li className="nav-item">
                  <Link className="nav-link" to={`/invoicing/?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&API_URL=${GlobalFunctions.extractToken().API_URL}&COMPANY_URL=${GlobalFunctions.extractToken().COMPANY_URL}&isXeroConnected=${GlobalFunctions.extractToken().isXeroConnected}`}>Invoicing</Link>
                </li> : ""
              }
            </ul>
          </div>
        </nav>
      </div >
    )
  }
}
