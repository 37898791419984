
import React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import '../styles/main.css';import ReactTable from 'react-table-6'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import 'react-table-6/react-table.css'
import { array, element } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../styles/navbar.css';
import Modal from "react-bootstrap/Modal";
import moment, { relativeTimeThreshold, duration } from 'moment'
import swal from '@sweetalert/with-react';
import date from "date-and-time";
import Spinner from 'react-bootstrap/Spinner'
import { white } from 'color-name';
import { formatDate } from 'tough-cookie';
import { Popover, OverlayTrigger } from "react-bootstrap"
import { Chart } from 'react-charts'
import GlobalFunctions from '../controllers/globalFunctions';
import Select from 'react-select';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import DatePicker from "react-datepicker";
import { BsEyeFill } from "react-icons/bs";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import "react-datepicker/dist/react-datepicker.css";
import { parseJSON } from 'date-fns';

//IMPORTING COMPONENTS;
import SweetAlertProgressBar from './components/SweetAlerts';


{/* ME COMPONENT */ }
class Dashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        DatabaseData: [],
        clients: [],
        finSupport: [],
        supportServices: [],
        linkedSupportAreas: [],
        linkedSupportService: [],
        linkedFinancialPackages: [],
        clientFin: [],
        servicePrice: 0,
        serviceCost: 0,
        isActiveSleepOver:null,
  
        clientId: 0,
        packageId: 0,
        supportAreaId: 0,
        supportServicesId: 0,
        staffId: 0,
        selectedStaffId: 0,
        selectedShiftId: 0,
        weekStart: '',
        weekEnd: '',
        selectedShiftDetails: {},
        financialPackageId: 0,
        staffList: [],
        timesheets: [],
        selectedTimesheetId: [],
        userDetails: {},
        selectedOpenShiftDetails: {},
        openShifts: [],
        weekStart: "",
        weekEnd: "",
        loadingData: true,
        savingData: false,
        staffAppointments: [],
        appointmentId: 0,
        totalTimesheetHours: 0,
        companyLocations : [],
        timesheets_data : [],
        trackingItemID : '',
        notes : '',
        breakMin: 0,
        showAddModal:false, selectedRow : 0
  
      };
      GlobalFunctions.extractToken();
    }
  
    getAppointments = (event) =>{
      this.setState({ loadingData: true })
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/getAppointments?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&shiftId=${event}`;
      axios.get(url1).then(client => {
        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    }

    handleCompleteAppointments = (event) =>{
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completeAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${event.appointmentId}`;
      axios.get(url1).then(client => {
        if (client.status == 200) {
          this.getAppointments(event.shiftId)
          swal("shift successfully completed!", "", "success")
            .then(() => {
  
            })
        }
      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    }
  
  
    getOpenShifts = (event) =>{
      this.setState({ loadingData: true })
      var url = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url)
        .then(res => {
          this.setState({ openShifts: res.data, loadingData: false })
        }).catch(error => {
  
        });
    }
  
    handleClaimOpenShift = (event) =>{
      this.setState({ savingData: true });
      
      const infor = {
        "staffId": Number(GlobalFunctions.extractToken().STAFF_ID),
        "shiftId": Number(event.shiftId),
        "location": event.locationId,
        "Date": event.date,
        "startTime": event.startTime,
        "endTime": event.endTime,
        "notes": event.notes,
        "allowance": 0,
        "isActiveSleepOver":event.isActiveSleepOver,
        "trackingItemID" : event.trackingItemID
  
      };
      if (!isNaN(event.shiftId)) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add3?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false });
          if (res.status == 201) {
            this.claimShiftAlert();
          }
  
        }).catch(error => {
          this.setState({ savingData: false });
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
  
        })
      }
        if(isNaN(event.shiftId)){
          var url = `${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${event.shiftId}`;
          axios.post(url, {staffId : Number(GlobalFunctions.extractToken().STAFF_ID)}).then(res=>{
            this.setState({ savingData: false });
            this.claimShiftAlert();
          }).catch(error => {
            this.setState({ savingData: false });
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
          })
        }
  
  
    }
  
    networkFailureAlert = (event) =>{
      swal(event, "", "warning")
        .then(() => {
  
        })
    }
    saveAlert =() =>{
      swal("shift successfully saved!", "", "success")
        .then(() => {
          this.setState({showAddModal:false})
          this.hideAddAllowanceModal();
        })
    }
    claimShiftAlert = () =>{
      swal("shift successfully claimed!", "", "success")
        .then(() => {
          this.setState({showAddModal:false})
          this.setState({ ShowClaimModal: false });
  
          this.hideAddAllowanceModal();
  
          this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd })
  
        })
    }
  
    deleteAlert = () =>{
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Your imaginary file is safe!");
          }
        })
    }
  
    handleInputChange = (event) =>{
      const target = event.target;
      const value = target.name === 'isGoing' ? target.checked : target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });
    }
  
    handleSubmit = (event) =>{
      this.setState({ savingData: true })
      event.preventDefault();
      const infor = {
        "staffId": GlobalFunctions.extractToken().STAFF_ID,
        "location": this.state.address,
        "Date": this.state.date,
        "startTime": this.state.startTime,
        "endTime": this.state.endTime,
        "breaking": this.state.breakMin,
        "notes": this.state.notes,
        "allowance": 0,
        "isActiveSleepOver":this.state.isActiveSleepOver,
        "trackingItemID" : this.state.trackingItemID
  
      };
  
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.state.address = 0
          this.state.date = ''
          this.state.startTime = ''
          this.state.endTime = ''
          this.state.breakMin = 0
          this.state.notes = ''
          this.setState({ savingData: false })
          if (res.status == 201) {
            this.saveAlert();
          }
  
  
        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
  
        })
    }

    hideAddAllowanceModal = () => {
      var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${GlobalFunctions.extractToken().STAFF_ID}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(timesheet => {
  
        this.setState({ timesheets: timesheet.data })
  
  
      }).catch(e => {
  
      })
      this.setState({ ShowAddAllowanceModal: false });
    }
  
    handleDataInput = (event)  => {
      this.setState({ [event.name]: event.value })
  
    }
  
    getTimesheets = (event)  => {
      this.setState({ loadingData: true })
      var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${GlobalFunctions.extractToken().STAFF_ID}&start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(timesheet => {
        var total = 0;
        timesheet.data.forEach(element => {
          total += element.duration
        })
        this.setState({ timesheets: timesheet.data,timesheets_data : timesheet.data, loadingData: false, totalTimesheetHours: total })
  
      }).catch(e => {
  
      })
    }
  
  
    calculateWeekRange = ()  => {
      let now = new Date();
      now = moment(now);
      const dayName = moment(now).format('dddd');
      var day = Number(now.weekday())
      var start = now.subtract(day - 1, 'days').format('MM/DD/YYYY');
      var end = now.add(6, 'days').format('MM/DD/YYYY');
      if(dayName=='Sunday'){
        end = moment(start).format('MM/DD/YYYY');
        end = moment(end).subtract(1, 'days').format('MM/DD/YYYY');
        start = moment(end).subtract(6, 'days').format('MM/DD/YYYY'); 
      }
      this.setState({ weekStart: start, weekEnd: end })
      this.getTimesheets({ weekStart: start, weekEnd: end });
      return {start : start, end : end}
    }
  
    getNextWeek = ()  => {
      var start = moment(this.state.weekEnd).format('MM/DD/YYYY'); 
      start = moment(start).add(1, 'days').format('MM/DD/YYYY'); 
      var end = moment(start).add(6, 'days').format('MM/DD/YYYY'); 
  
      this.setState({ weekStart: start, weekEnd: end })
      this.getTimesheets({ weekStart: start, weekEnd: end });
  
    }
  
    getPreviousWeek = () =>  {
      var end = moment(this.state.weekStart).format('MM/DD/YYYY'); 
      end = moment(end).subtract(1, 'days').format('MM/DD/YYYY'); 
      var start = moment(end).subtract(6, 'days').format('MM/DD/YYYY'); 
      this.setState({ weekStart: start, weekEnd: end })
      this.getTimesheets({ weekStart: start, weekEnd: end });
    }
    getClients = ()  => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ clients: client.data });
      })
  
    }
    
    cancelAppointmentAlert=(event)=>{
      var BreakException = {};
      try {
        var pos =0;
        this.state.selectedTimesheetId.forEach(data=>{
          if(data.isCompleted) throw BreakException;
          pos++;
          if(pos==this.state.selectedTimesheetId.length){
            this.handleCancelClientAppointmentShift(this.state.selectedTimesheetId);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Completed Shifts. They can't be Cancelled!",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleCancelClientAppointmentShift(this.state.selectedTimesheetId);
           }
          })
      }
  }
  
    handleCancelClientAppointmentShift = (ids) =>{
      var pos=0;
      var success=0;
      this.state.selectedTimesheetId.forEach(data=>{
        var element = data.shiftId
        var url1 =''
        if(isNaN(element)){
          url1= `${GlobalFunctions.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        }else{
          url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancel?shiftId=${element}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        }
        axios.get(url1).then(client => {
          pos++; 
          success++;
          this.cancelShiftAlert(ids.length, success);
        }).catch(error => {
          pos++;
          this.cancelShiftAlert(ids.length, success);
        })
      })
      
    }
  
    cancelShiftAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is cancelled successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.setState({ selectedTimesheetId: [] });
          this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
        })
    }
  
    completeAppointmentAlert_01=(event)=>{
      var BreakException = {};
      let now = new Date();
      now = moment(now).format('MM/DD/YYYY');
      try {
        var pos =0;
        this.state.selectedTimesheetId.forEach(data=>{
          if(!data.isApproved || moment(data.date).format('MM/DD/YYYY')>now) throw BreakException;
          pos++;
          if(pos==this.state.selectedTimesheetId.length){
            this.handleConfirmAttendance(this.state.selectedTimesheetId);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Unapproved shifts or Upcoming shifts",
            text: "They will NOT be changed status to Completed",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleConfirmAttendance(this.state.selectedTimesheetId);
           }
          })
      }
  }
  
    handleConfirmAttendance = (ids)  =>  {
      let now = new Date();
      now = moment(now).format('MM/DD/YYYY');
      var pos=0;
      var success=0;
      ids.forEach(data => {
        var id = data.shiftId;
        if (isNaN(id)) {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completeAppointment?date=${now}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${id}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
                this.confirmAlert(ids.length, success);
            }
          }).catch(error => {
            pos++;
              this.confirmAlert(ids.length, success);
          })
        } else {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completed?date=${now}&shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
                this.confirmAlert(ids.length, success);
            }
          }).catch(error => {
            pos++;
              this.confirmAlert(ids.length, success);
          })
        }
      }
      )
  
    }
  
    confirmAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is successfully completed`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.setState({ selectedTimesheetId: [] })
          this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
        })
    }
  
    handleConfirmShift = (selectedShiftId)  => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
  
      })
  
    }
  
    componentDidMount() {
      this.calculateWeekRange();
      GlobalFunctions.requestUserFromAPI().then(ok=>{
        GlobalFunctions.extractToken();
        this.calculateWeekRange();
        this.getOpenShifts({ weekStart: this.calculateWeekRange().start, weekEnd: this.calculateWeekRange().end });
        this.getClients();
        GlobalFunctions.getCompanyLocations().then(res=>{
          this.setState({companyLocations : res});
        });
      })
      
    }
  
    globalSearch = () => {
      let { searchInput } = this.state;
      let filteredData = this.state.timesheets_data.filter(value => {
        return (
          (
            (value.date?moment(value.date).format('dddd').toString().toLowerCase().includes(searchInput.toLowerCase()):false )||
            (value.notes?value.notes.toLowerCase().includes(searchInput.toLowerCase()) : false )||
            (value.location?value.location.toLowerCase().includes(searchInput.toLowerCase()) : false )||
            (value.clientName?value.clientName.toLowerCase().includes(searchInput.toLowerCase()) : false )||
            (value.date?value.date.toLowerCase().includes(searchInput.toLowerCase()):false )||
            (value.date?(moment(value.date).format('dddd')).toLowerCase().includes(searchInput.toLowerCase()):false)
          )
        );
      });
      this.setState({ timesheets: filteredData });
    };
  
    handleChange = event => {
      this.setState({ searchInput: event.target.value }, () => {
        this.globalSearch();
      });
    };
  
    render() {
    const columnsMobile = [{
      Header: () => (
        <button
          onClick={() => {
            var arr = [];
            arr = this.state.selectedTimesheetId;
            if (arr.length == 0) {
              this.state.timesheets.forEach(element => {
                arr.push(element)
              });
              this.setState({ selectedTimesheetId: arr });
            } else {
              this.setState({ selectedTimesheetId: [] });
            }
          }}
          className="btn text-white btn-warning btn-block"
          type="button">
          &#10003;
        </button>
      ),
      maxWidth: 50,
      Cell: row => {
  
        return (
          <div
          >
            <input
              onClick={() => {
                var arr = [];
                arr = this.state.selectedTimesheetId;
                var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                if (index >= 0) {
                  arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                } else {
                  arr.push(row.original)
                }
                this.setState({ selectedTimesheetId: arr })
              }
              }
              type="checkbox" className="ml-2" checked={this.state.selectedTimesheetId.some(item =>item.shiftId==row.original.shiftId) ? true : false}>
            </input>
          </div >
        )
      }
  
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Task Description
        </button>
      ),
      Cell : row =>{
        return (
            <div
              onClick={() => {
                var arr = [];
                arr = this.state.selectedTimesheetId;
                var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                if (index >= 0) {
                  arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                } else {
                  arr.push(row.original)
                }
                this.setState({ selectedTimesheetId: arr })
              }
              } 
            >
              <div>{`${moment(row.original.date).format('dddd')} ${moment(row.original.date).format('D')} ${moment(row.original.date).format('MMMM')} ${moment(row.original.date).format('YYYY')} from ${row.original.startTime} to ${row.original.endTime}`}</div>
              <textarea 
              disabled
              style={{'background':this.state.selectedTimesheetId.some(item =>item.shiftId==row.original.shiftId)?'#b7b8ab':''}}
              className = "w-100"
                value={`Attending ${row.original.clientName?row.original.clientName:`SIL/Admin Shift`}  from ${row.original.startTime} to ${row.original.endTime} at ${row.original.location}. Notes: ${row.original.notes}`}
                rows="5"
              ></textarea>
              <div>
                {
                  row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-info ml-2">Unapproved</span></small></a>
                }
                {
                  row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-info ml-2">Incomplete</span></small></a>
                }
                {
                  row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-info ml-2">Unconfirmed</span></small></a>
                }
                {
                  row.original.isConcelled ? <a className="label label-info"><small><span className="badge badge-danger ml-2">Cancelled</span></small></a> : <a className="label label-info"><small><span className="badge badge-info ml-2">Uncancelled</span></small></a>
                }
              </div>
            </div>
        )
      },  
    }
  ]
      const tableAppointments = [
        {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              Client name
            </button>
          ),
          accessor: 'clientName',
          maxWidth: 160
  
        }, {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              Start
            </button>
          ),
          accessor: 'startTime',
          maxWidth: 70
  
        }, {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              End
            </button>
          ),
          accessor: 'endTime',
          maxWidth: 70
        }, {
  
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              Location
            </button>
          ),
          accessor: 'clientLocaton',
          maxWidth: 200
  
        }, {
  
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              Notes
            </button>
          ),
          accessor: 'notes',
          maxWidth: 250
        }, {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              Actions
            </button>
          ),
          Cell: row => {
            return (
              <div>
                {
                  <button
                    type="button"
                    class="btn btn-outline-dark btn-sm mr-1"
                    onClick={() => {
                      var id = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                      this.setState({ appointmentId: id });
                      this.setState({ ShowAddAllowanceModal: true });
  
                    }}
                  >
                    <small>&#43; Allowance</small>
                  </button>
  
                }
                {
  
                  row.original.isApproved ? <button
                    type="button"
                    class="btn btn-success btn-sm mr-1"
                  >
                    <small>Approved</small>
                  </button>
                    :
                    <button
                      type="button"
                      class="btn btn-outline-success btn-sm mr-1"
                    >
                      <small>UnApproved</small>
                    </button>
  
                }
                {
                  row.original.isConfirmed ?
                    <button
                      type="button"
                      class="btn btn-warning btn-sm mr-1"
  
                    >
                      <small>Confirmed</small>
                    </button>
                    :
                    <button
                      type="button"
                      class="btn btn-outline-warning btn-sm mr-1"
                    >
                      <small>UnConfirmed</small>
                    </button>
                }
  
                {
                  row.original.isComplete ?
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                    >
                      <small>Completed</small>
                    </button>
                    :
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        var id = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                        this.handleCompleteAppointments({ appointmentId: id, shiftId: row.original.shiftId })
                      }}
                    >
                      <small>Complete</small>
                    </button>
                }
  
              </div>
            )
          }
        }]
  
      const columns2 = [
        {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              <small>Date & Time</small>
            </button>
          ),
          minWidth:75,
          Cell: row =>{
            return (
            <small>
              {moment(row.original.date).format('ddd')} {moment(row.original.date).format('DD')} {moment(row.original.date).format('MMM')} {moment(row.original.date).format('YYYY')}
              <br></br>@ {row.original.startTime} to {row.original.endTime}
            </small>
            )
          }
  
        }, {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              <small>Shift Location</small>
            </button>
          ),
          minWidth : 220,
          Cell: row =>{
            return (
          <small>Notes: {row.original.notes}<br></br> @{row.original.clientLocaton} {row.original.clientLocation}</small>
            )
          }
  
        }, {
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              <small>
              Participant/Type
              </small>
            </button>
          ),
          Cell: row =>{
            return (
            <small>{row.original.clientName?row.original.clientName : `Office Shift`}</small>
            )
          }
  
        }, {
  
          Header: () => (
            <button className="btn text-white btn-warning btn-block" type="button">
              <small>Actions</small>
            </button>
          ),
          maxWidth:80,
          Cell: row => {
            return (
              <small>
              <div>
                {
                  this.state.savingData && this.state.selectedRow.selectedRow==row.original.shiftId ?
                    <button
                      disabled
                      type="button"
                      className="btn btn-sm btn-outline-success btn-block mr-2"
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <small>
                      Claiming...
                      </small>
                        
                  </button>
                    :
                    <button
                      onClick={() => {
                        this.setState({selectedRow : row.original.shiftId});
                        this.handleClaimOpenShift(row.original);
                      }}
                      type="button"
                      className="btn btn-sm btn-outline-success btn-block mr-2"
                    >
                      <small>
                        Claim shift
                      </small>
                  </button>
                }
              </div>
              </small>
            )
          }
        }]
  
      return (
        <div>
          <BrowserView>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
              <h1 className="h2">Me</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <button
                    type="button"
                    className="btn btn-sm btn-secondary mr-2 disabled"
                  >
                    {this.state.timesheets.length} Total shifts
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-success mr-2"
                >
                  Total: {parseFloat(this.state.totalTimesheetHours).toFixed(2)} Hrs
              </button>
                <div className="btn-group mr-2">
                  <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                  <button type="button" className="btn btn-sm btn-outline-secondary">
                    <span data-feather="calendar"></span>{`${moment(this.state.weekStart).format('ddd')} ${moment(this.state.weekStart).format('DD')} ${moment(this.state.weekStart).format('MMM')}`} - {moment(this.state.weekEnd).format('ddd')} {moment(this.state.weekEnd).format('MM')} {moment(this.state.weekEnd).format('MMM')}</button>
                  <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                </div>
  
                <button
                    onClick={()=>{
                      this.setState({showAddModal:true})
                    }}
                    type="button"
                    className="btn btn-sm btn-success mr-2"
                  >
                    &#43; Add Shift
                </button>
  
                <button
                  onClick={() => {
                    this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd });
                    this.setState({ ShowClaimModal: true });
                  }
                  }
                  type="button"
                  className="btn btn-sm btn-primary mr-2"
                >
                  Claim open shift(s)
              </button>
              
                <button
                  type="button"
                  onClick={this.completeAppointmentAlert_01}
                  className="btn btn-sm btn-warning mr-2"
                > &#10003; Completed
              </button>
              <button
                  type="button"
                  onClick={() => {
                    this.cancelAppointmentAlert(this.state.selectedTimesheetId);
                  }}
                  className="btn btn-sm btn-danger mr-2"
                > &#10005; Cancel Shift(s)
              </button>
              
                <button
  
                  type="button"
                  className="btn btn-sm btn-outline-secondary mr-2"
                  onClick={() => {
                    this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                  }}
                >
                  &#8635; Refresh
              </button>
  
              </div>
            </div>
          </BrowserView>
          
          {/* Mobile View Nav */}
          <MobileView>
            <div>
              <div className=" justify-content-center d-flex flex-row-reverse bd-highlight">
              <div className="p-2 bd-highlight">
              <button
                  type="button"
                  onClick={() => {
                    this.cancelAppointmentAlert(this.state.selectedTimesheetId);
                  }}
                  className="btn btn-sm btn-danger mr-2"
                > Cancel
              </button>
              </div>
                
                <div className="p-2 bd-highlight">
                  <button
                    type="button"
                    onClick={this.completeAppointmentAlert_01}
                    className="btn btn-sm btn-warning mr-2"
                  >Completed
                </button>
                </div>
                <div className="p-2 bd-highlight">
                  <button
                      onClick={()=>{
                        this.setState({showAddModal:true})
                      }}
                      type="button"
                      className="btn btn-sm btn-success "
                    >
                      &#43;
                  </button>
                </div>
                <div className="p-2 bd-highlight">
                  <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                      }}
                    >
                      &#8635;
                  </button>
                </div>
              </div>
              <div className=" justify-content-center d-flex flex-row bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <div className="btn-group ">
                    <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary">
                      <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD/MM/YYYY')} - {moment(this.state.weekEnd).format('DD/MM/YYYY')}</button>
                    <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                  </div>
                </div>
                <div className="p-2 bd-highligh">
                  <button
                      onClick={() => {
                        this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd });
                        this.setState({ ShowClaimModal: true });
                      }
                      }
                      type="button"
                      className="btn btn-sm btn-primary"
                    >
                      Open shift(s)
                  </button>
                </div>
              </div>
            </div>
          </MobileView>
          {/* END OF MOBILE NAV */}
          <div className="form-row">
            <div className="col-md-12">
              <BrowserView>
              <div className="col-md-6" >
                <input
                  className="form-control ml-1 "
                  type="text"
                  placeholder="Search by either Shift Day or Client Name or Notes or Location..."
                  defaultValue={this.state.searchInput}
                  onChange={this.handleChange}
                  autoFocus />
              
              <div style={{ height: "500px", overflowY: "scroll" }}>
                  {
                    this.state.loadingData ?
                      <div style={{"margin" : "10"}}>
                        <Spinner animation="grow" />
                        <Spinner animation="grow" />
                        <Spinner animation="grow" />
                        
                      </div>
                      :
                      this.state.timesheets.map((row) =>
                        <small>
                        <div
                          onClick={() => {
                            var arr = [];
                            arr = this.state.selectedTimesheetId;
                            var index = arr.findIndex(x => x.shiftId == row.shiftId);
                            if (index >= 0) {
                              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                            } else {
                              arr.push(row)
                            }
                            this.setState({ selectedTimesheetId: arr })
                          }
                          }
                          class={this.state.selectedTimesheetId.some(item=>item.shiftId==row.shiftId) ? "card text-dark bg-light" : "card"} 
                          style={{"width":"100%", "height":"95px"}}>
                            <div class="card-body">
                              
                              <div className="row mt-0">
                                {
                                  row.isConcelled?
                                    <del><h6 class="mt-0">{`${moment(row.date).format('ddd')} ${moment(row.date).format('D')} ${moment(row.date).format('MMMM')} ${moment(row.date).format('YYYY')} from ${row.startTime} to ${row.endTime} for ${parseFloat(row.duration).toFixed(2)} hours`} </h6></del>
                                    :
                                    <h6 class="mt-0">{`${moment(row.date).format('ddd')} ${moment(row.date).format('D')} ${moment(row.date).format('MMMM')} ${moment(row.date).format('YYYY')} from ${row.startTime} to ${row.endTime} for ${parseFloat(row.duration).toFixed(2)} hours`}</h6>
                                }
                                &nbsp;<h6>{row.clientName?`[Participant: ${row.clientName}]`:``}</h6>
                              </div>
                              
                              <h7 class="mb-2 text-muted row">
                                Location: <i style={{"width" :"50%", "height":"5", "overflow":"scroll"}}>{row.location}</i>  &nbsp;
                                <div>
                                  {
                                    row.isApproved ? <a className="label label-info"><span className="badge badge-success ml-2">Approved</span></a> : <a className="label label-info"><span className="badge badge-danger ml-2">Not approved</span></a>
                                  }
                                  {
                                    row.isCompleted ? <a className="label label-info"><span className="badge badge-warning ml-2">Completed</span></a> : <a className="label label-info"><span className="badge badge-danger ml-2">Incompleted</span></a>
                                  }
                                  {
                                    row.isAdminConfirmed ? <a className="label label-info"><span className="badge badge-info ml-2">Confirmed</span></a> : <a className="label label-info"><span className="badge badge-danger ml-2">Unconfirmed</span></a>
                                  }
                                </div>
                               </h7>
                              <p class="card-text"><b>Shift Notes : </b>{row.notes}</p>
                            
                            </div>
                          </div>
                          </small>
                      )
                  }
                </div>
              </div>
              </BrowserView>
              <MobileView>
                <ReactTable
                  data={this.state.timesheets}
                  loading={this.state.loadingData}
                  columns={columnsMobile}
                  defaultPageSize={10}
                />
              </MobileView>
            </div>
       
            
  
  
            {/* CLAIM SHIFT MODAL */}
            <Modal show={this.state.ShowClaimModal} onHide={true} size="lg" >
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Claim shift(s)</h5></Modal.Title>
                <button type="button" className="close" onClick={()=>{
                  this.setState({ ShowClaimModal: false })
                }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
  
                  <div className="col-md-12">
                    <ReactTable
                      data={this.state.openShifts}
                      loading={this.state.loadingData}
                      columns={columns2}
                      defaultPageSize={10}
                    />
                  </div>
  
                </div>
              </Modal.Body>
              <Modal.Footer>
  
              </Modal.Footer>
            </Modal>
  
            {/* ADD SHIFT MODAL */}
            <Modal show={this.state.showAddModal} onHide={true} size="lg" >
              <form onSubmit={this.handleSubmit}>
                <Modal.Header>
                  <Modal.Title><h5 className="modal-title" >Add shift</h5></Modal.Title>
                  <button type="button" className="close" onClick={()=>{
                    this.setState({showAddModal:false})
                  }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
  
                    <div className="col-md-12">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputAddress">Location</label>
                          <select  
                            onChange={(event) => {
                              const index=this.state.companyLocations.findIndex(x=>x.id==event.target.value);
                              this.setState({trackingItemID: this.state.companyLocations[index].trackingItemID})
                              this.handleDataInput(event.target)}
                            }
                            className="form-control" value={this.state.address} name="address">
                            <option >---Select Location---</option>
                            {
                              this.state.companyLocations.map((element) =>
                                <option value={element.id}>{element.name} ({element.address})</option>
                              )
                            }
                          </select>
  
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputAddress">Date</label>
                          {/* <DatePicker name="date" required selected={this.state.date} className="form-control" onChange={date => {
                            const today=moment(date).format('MM/DD/YYYY')
                            console.log(today)
                            this.setState({date : today});
                            }} /> */}
                          <input  required type="date" className="form-control" value={this.state.date} name="date" onChange={event => this.handleDataInput(event.target)} />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Start time</label>
                          <input required type="time" value={this.state.startTime} className="form-control" onChange={event => this.handleDataInput(event.target)} name="startTime" />
  
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">End time</label>
                          <input required type="time" value={this.state.endTime} className="form-control" onChange={event => this.handleDataInput(event.target)} name="endTime" />
  
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Break(min)</label>
                          <input value={this.state.breakMin} type="number" className="form-control" onChange={event => this.handleDataInput(event.target)} name="breakMin" />
  
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Notes</label>
                        <textarea value={this.state.notes} className="form-control" onChange={event => this.handleDataInput(event.target)} name="notes" rows="3"></textarea>
  
                      </div>
  
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {
                    this.state.savingData ?
                      <button
                        className="btn btn-warning"
                        type="Submit"
                        disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Saving data...
                  </button>
                      :
                      <button
                        className="btn btn-warning"
                        type="Submit">
                        Save shift
                  </button>
                  }
  
                </Modal.Footer>
              </form>
            </Modal>
  
            {/* ADD ALLOWANCE MODAL */}
  
            <Modal show={this.state.ShowAddAllowanceModal} onHide={true} size="lg" >
              <form onSubmit={this.handleAddAllowance}>
                <Modal.Header>
                  <Modal.Title><h5 className="modal-title" >Add Allowance</h5></Modal.Title>
                  <button type="button" className="close" onClick={this.hideAddAllowanceModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
  
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputCity">Amount</label>
                          <input required type="number" className="form-control" onChange={event => this.handleDataInput(event.target)} name="amount" />
  
  
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Description</label>
                        <textarea required className="form-control" onChange={event => this.handleDataInput(event.target)} name="description" rows="3"></textarea>
  
  
                      </div>
  
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {
                    this.state.savingData ?
                      <button
                        className="btn btn-warning"
                        type="Submit"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Saving data...
                  </button>
                      :
                      <button
                        className="btn btn-warning"
                        type="Submit"
                      >
                        Add allowance
                  </button>
                  }
  
                </Modal.Footer>
              </form>
            </Modal>
  
            {/* ADD CLIENT SHIFT MODAL */}
            <Modal show={this.state.ShowAddAppointmentModal} onHide={true} size="xl"  >
  
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Client shifts</h5></Modal.Title>
                <button type="button" className="close" onClick={()=>{this.setState({ ShowAddAppointmentModal: false })}}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Participant shifts</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
  
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <ReactTable
                      data={this.state.staffAppointments}
                      loading={this.state.loadingData}
                      columns={tableAppointments}
                      defaultPageSize={10}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
  
              </Modal.Footer>
  
            </Modal>
  
          </div>
  
        </div >
      )
    }
  }
  
  export default Dashboard;