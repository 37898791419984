
  import React from 'react'
  import ReactDOM from 'react-dom';
  import axios from 'axios';
  import '../styles/main.css';
  import ReactTable from 'react-table-6'
  import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
  import 'react-table-6/react-table.css'
  import { array, element } from 'prop-types';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/js/bootstrap.bundle';
  import '../styles/navbar.css';
  import Modal from "react-bootstrap/Modal";
  import moment, { relativeTimeThreshold, duration } from 'moment'
  import swal from '@sweetalert/with-react';
  import date from "date-and-time";
  import Spinner from 'react-bootstrap/Spinner'
  import { white } from 'color-name';
  import { formatDate } from 'tough-cookie';
  import { Popover, OverlayTrigger } from "react-bootstrap"
  import { Chart } from 'react-charts'
  import GlobalFunctions from '../controllers/globalFunctions';
  import Select from 'react-select';
  import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
  import { Progress } from 'react-sweet-progress';
  import "react-sweet-progress/lib/style.css";
  import DatePicker from "react-datepicker";
  import { BsEyeFill } from "react-icons/bs";
  import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
  import 'rsuite-table/dist/css/rsuite-table.css';
  
  import "react-datepicker/dist/react-datepicker.css";
  import { parseJSON } from 'date-fns';

//IMPORTING COMPONENTS;
import SweetAlertProgressBar from './components/SweetAlerts';
import BigdataTable from './components/bitDataTable';
  
  {/* CALENDAR COMPONENT */ }
class Schedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      supportServicesPerClient : [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      addExtraHourForInvoicing : false,
      selectedShiftDetails: {},
      financialPackageId: 0,
      allowance: 0,
      userDetails: {},
      totalShifts: {
        totalAdminConfirmed:0,
        totalCompleted : 0,
        totalApproved : 0,
        totalShifts : 0
      },
      copiedWeekShifts: [],
      getNextWeeks: [],
      clientLocation: "",
      staffAppointments: [],
      costPerHour: 0,
      calculatedDuration: 0,
      editedAppointments: {
        clientId:0,
        staffId: 0,
        appointmentId: 0,
        startTime: "",
        endTime: "",
        serviceId: 0,
        location: "",
        locationId:0,
        notes: "",
        date : ""
      },
      isCordinator: false,
      availability: false,
      loadingData: true,
      savingData: false,
      isOpenListOfServices: false,
      selectedServiceName: '',
      isSubmitingInProgress: false,
      clientList: [],
      staffList : [],
      shiftsList: [],
      filteredData: [],
      searchInput: '',
      selectedListClientAppointmentIds : [],
      selectedRowId : 0,
      selectedLocationShiftsIds : [],
      selectedStaffIds : [],
      clientShiftOptionsShow : true,
      locationShifts : [],
      companyLocations : [],
      hideLocationShiftInputFields : false,
      lastFilterApplied : 'Location',
      coppiedShistDetails : {locationShifts :[], appointments : []},
      filterSchedulesByLocation : 0,
      filterStaffByLocationValue : {label : 'Filter By Location', value : 0},
      locationSelectList : [
        {label : 'All Locations', value : 0}
      ],
      userLocationData : [],
      staffUnderSelectedFilter : [],
      userManagerData : [],
      filterSchedulesByManagerId : 0,
      filterSchedulesByManagerValue : {label : 'Filter By Manager', value : 0},
      managersSeleectList : [
        {label : 'All Managers', value : 0}
      ],
      clientSelectedValue : {label : 'Select Participant', value : 0},
      clientSelectedValueEdit :null,
      clientSelectList : [],
      serviceSelectedValue : {label : '', value : 0},
      selectedFilterItermId : 0, 
      serviceSelectList : [],
      serviceSelectListEdit : [],
      staffUnderManager : [],
      staffUnderLocation : [],
      isStaffAvailable : true,
      isActiveSleepOver : null,
      startTime: '',
      endTime : '',
      notes : '',
      staffId :0,
      progress : 0,
      breakMin : 0,
      clientLocaton:'',
      shiftId : 0,
      locationShiftEditModal : false,
      remainingWeekDays : [], 
      selectedRecuringDays : [],
      upcomingWeekDays : [],
      weekOrFortnightSelected: false,
      selectedReatPeriod : 'ThisWeek',
      trackingItemID : ''
    };

  }
  getLocationUserData = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userProperty?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({userLocationData : res.data});
    })
  }

  getManagerUserData = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userManager?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({userManagerData : res.data});
    })
  }

  handleChangeStaffNameFilter= event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.filterStaffByName();
    });
  };

  handleChangeLocationIdFilter = event => {
    this.setState({ filterSchedulesByLocation : event.value , filterStaffByLocationValue : event}, () => {
      this.filterStaffByLocation();
    });
  };

  filterStaffByName = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        value.staffName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ DatabaseData: filteredData });
  };

  filterStaffByLocation = () => {
    const arr = this.state.staffUnderSelectedFilter;
    let { filterSchedulesByLocation } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        arr.includes(value.staffId)
      );
    });
    if (Number(filterSchedulesByLocation)==0) {
      this.setState({ DatabaseData:  this.state.shiftsList, staffUnderSelectedFilter : []});
    }else{
      this.setState({ DatabaseData: filteredData, staffUnderSelectedFilter : [] });
    }
  };
  
  handleChangeManagerIdFilter = event => {
    this.setState({ filterSchedulesByManagerId : event.value, filterSchedulesByManagerValue: event }, () => {
      this.filterStaffByManager();
    });
  };

  filterStaffByManager = () => {
    const arr = this.state.staffUnderSelectedFilter;
    let { filterSchedulesByManagerId } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
       arr.includes(value.staffId)
      );
    });
    if (Number(filterSchedulesByManagerId)==0) {
      this.setState({ DatabaseData:  this.state.shiftsList, staffUnderSelectedFilter : []});
    }else{
      this.setState({ DatabaseData: filteredData , staffUnderSelectedFilter : []});
    }
  };

  manageFiltersAfterUpdate =()=>{
    if (this.state.lastFilterApplied=='Location') {
      var pos = 0 ;
      this.state.userLocationData.forEach(element=>{
        pos++;
        if(this.state.selectedFilterItermId==element.propertId){
          this.state.staffUnderSelectedFilter.push(element.staffId);
        }
        if(pos==this.state.userLocationData.length){
          this.filterStaffByLocation();
        }
      })
    }else if(this.state.lastFilterApplied=='Staff'){
      this.filterStaffByName();
    }else if(this.state.lastFilterApplied=='Manager'){
      var pos = 0;
      this.state.userManagerData.forEach(element=>{
        pos++;
        if(this.state.selectedFilterItermId==element.managerId){
          this.state.staffUnderSelectedFilter.push(element.staffManaged);
        }
        if(pos==this.state.userManagerData.length){
          this.filterStaffByManager();
        }
      })
    }
  }

  handleApproveTimesheets123 = () => {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/shifts/bulkApprove?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(result => {
      this.state.totalShifts.totalApproved = this.state.totalShifts.totalShifts;
      this.setState({loadingData: false});
      var data = this.state.DatabaseData;
      data.forEach(element=>{
        element.shifts.forEach(shift=>{
          shift.isApproved=true
        })
      })
      this.setState({DatabaseData : data})
      swal("Successfully approved!", " ", "success");
    }).catch(error => {
      console.log(error);
      swal("Not Approved!", " ", "warning");
    })
  }

  calculateDuration123 = (event) =>{
    var startTime = event.startTime&&event.endTime?date.parse(event.startTime, 'HH:mm:ss A', true):null;
    var endTime =event.startTime&&event.endTime? date.parse(event.endTime, 'HH:mm:ss A', true):null;
    var duration = event.startTime&&event.endTime?date.subtract(endTime, startTime).toHours():0;
    duration = duration<0?24+duration:duration;
    this.setState({ calculatedDuration: duration });
    return duration;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event, recuring) =>{
    event.preventDefault();
    var pos=0;
    recuring.push(this.state.selectedShiftDate);
    this.setState({ savingData: true, selectedRecuringDays: []});
    const data = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,
      "isActiveSleepOver" : this.state.isActiveSleepOver,
      "trackingItemID": this.state.trackingItemID
    }
    this.setState({
      startTime : '', 
      endTime : '', 
      notes : '', 
      breakMin : 0,
      selectedRecuringDays:[]
    })
    recuring.forEach(element=>{
      const infor = {
        "staffId": Number(data.staffId),
        "location": data.location,
        "Date": element,
        "startTime": data.startTime,
        "endTime": data.endTime,
        "breaking": data.breaking,
        "notes": data.notes,
        "allowance": 0,
        "isActiveSleepOver" : data.isActiveSleepOver,
        "trackingItemID": data.trackingItemID
      };
  
      if (Number(this.state.selectedStaffId) == -1) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.state.totalShifts.totalShifts+=1;
            this.setState({ savingData: false })
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
                arr= this.state.DatabaseData;
              var arr2=this.state.locationShifts;
                  const index= arr.findIndex(x => x.staffId == Number(-1));
                  const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                  arr[index].shifts.push({
                    "shiftId" : res.data.data.shiftId,
                    "staffId": -1,
                    "locationId" : this.state.companyLocations[locationPos].id,
                    "location" : this.state.companyLocations[locationPos].id,
                    "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                    "date": res.data.data.Date,
                    "day":moment(res.data.data.Date).format('dddd'),
                    "startTime": res.data.data.startTime,
                    "endTime": res.data.data.endTime,
                    "notes": res.data.data.notes,
                    "breaking": res.data.data.breaking,
                    "isClientShift" : false,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                    "trackingItemID": data.trackingItemID
                  });
                  if(element==this.state.selectedShiftDate){
                    arr2.push({
                      "shiftId" : res.data.data.shiftId,
                      "staffId": -1,
                      "locationId" : this.state.companyLocations[locationPos].id,
                      "location" : this.state.companyLocations[locationPos].id,
                      "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                      "date": res.data.data.Date,
                      "day":moment(res.data.data.Date).format('dddd'),
                      "startTime": res.data.data.startTime,
                      "endTime": res.data.data.endTime,
                      "notes": res.data.data.notes,
                      "breaking": res.data.data.breaking,
                      "isClientShift" : false,
                      "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                      "trackingItemID": data.trackingItemID
                    })
                  }
                  
                  this.state.DatabaseData = arr;
                  this.state.locationShifts = arr2;
                  
                 
                  pos++;
                  
                  this.manageFiltersAfterUpdate();
              this.saveAlert();
            }
  
          }).catch(error => {
            pos++;
            this.setState({ savingData: false })
  
          });
      } else {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.state.totalShifts.totalShifts+=1;
            this.setState({ savingData: false })
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
                arr= this.state.DatabaseData;
              var arr2=this.state.locationShifts;
                  const index= arr.findIndex(x => x.staffId == Number(res.data.data.staffId));
                  const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                  arr[index].shifts.push({
                    "shiftId" : res.data.data.shiftId,
                    "staffId": res.data.data.stafffId,
                    "locationId" : this.state.companyLocations[locationPos].id,
                    "location" : this.state.companyLocations[locationPos].id,
                    "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                    "date": res.data.data.Date,
                    "day":moment(res.data.data.Date).format('dddd'),
                    "startTime": res.data.data.startTime,
                    "endTime": res.data.data.endTime,
                    "notes": res.data.data.notes,
                    "breaking": res.data.data.breaking,
                    "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                    "isClientShift" : false,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "trackingItemID": data.trackingItemID
                  });
                  if(element==this.state.selectedShiftDate){
                    arr2.push({
                      "shiftId" : res.data.data.shiftId,
                      "staffId": res.data.data.stafffId,
                      "locationId" : this.state.companyLocations[locationPos].id,
                      "location" : this.state.companyLocations[locationPos].id,
                      "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                      "date": res.data.data.Date,
                      "day":moment(res.data.data.Date).format('dddd'),
                      "startTime": res.data.data.startTime,
                      "endTime": res.data.data.endTime,
                      "notes": res.data.data.notes,
                      "breaking": res.data.data.breaking,
                      "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                      "isClientShift" : false,
                      "trackingItemID": data.trackingItemID
                    })
                  }
                  
                this.state.DatabaseData = arr;
                this.state.locationShifts = arr2;
                pos++;
                if(pos==recuring.length){
                  this.setState({
                    startTime : '', 
                    endTime : '', 
                    notes : '', 
                    breakMin : 0,
                    selectedRecuringDays:[]
                  })
                }
                this.manageFiltersAfterUpdate();
              this.saveAlert();
              this.saveAlert();
            }
  
          }).catch(error => {
            pos++;
            console.log(error)
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
            this.setState({ savingData: false })
          })
      }
    })
    


  }
  handleAddAppointment = (event, recuring) =>{
    this.setState({ savingData: true, selectedRecuringDays: [] })
    event.preventDefault();
    var pos=0;
    recuring.push(this.state.selectedShiftDate);
    var valid = true;
    const data={
      "isCordinator": this.state.isCordinator,
      "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
      "location": this.state.clientLocation,
      "date": element,
      "shiftId": Number(this.state.selectedShiftId),
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "clientId": Number(this.state.clientId),
      "notes": this.state.notes,
      "serviceId": Number(this.state.clientServiceId),
      "costPerHour": this.state.costPerHour,
      "isActiveSleepOver" : this.state.isActiveSleepOver,
      "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
      "trackingItemID": this.state.trackingItemID
    }
    if (this.state.clientId == 0 || isNaN(this.state.clientServiceId)) {
      valid = false;
      this.setState({ savingData: false });
      if (this.state.clientId == 0) {
        swal("Select Client Please!", " ", "warning");
      }
      else if (isNaN(this.state.clientServiceId)) {
        swal("Select Service Please!", " ", "warning");
      }
    }
    this.setState({
      startTime : '', 
      endTime : '', 
      notes : '', 
      clientLocation :'',
      breakMin : 0,
      serviceSelectList : [],
      serviceSelectedValue : '',
      costPerHour : 0,
      clientSelectedValue : '',
      clientId : 0,
      clientServiceId : 'No value',
      addExtraHourForInvoicing : false,
      selectedRecuringDays:[]
    })
    recuring.forEach(element=>{
      const infor = {
        "isCordinator": data.isCordinator,
        "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
        "location": data.location,
        "date": element,
        "shiftId": Number(this.state.selectedShiftId),
        "startTime": data.startTime,
        "endTime": data.endTime,
        "clientId": Number(data.clientId),
        "notes": data.notes,
        "serviceId": Number(data.serviceId),
        "costPerHour": data.costPerHour,
        "isActiveSleepOver" : data.isActiveSleepOver,
        "addExtraHourForInvoicing" : data.addExtraHourForInvoicing,
        "trackingItemID": data.trackingItemID
      };
  
      if (valid) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/appointStaff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.state.totalShifts.totalShifts+=1;
            this.setState({ savingData: false });
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              arr2=this.state.staffAppointments
                const index= arr.findIndex(x => x.staffId == this.state.selectedStaffId);
                const clientPos=this.state.clients.findIndex(x => x.id == Number(data.clientId));
                const servicePos=this.state.serviceSelectList.findIndex(x => x.value == Number(data.serviceId));
              
                arr[index].shifts.push({
                  "shiftId" : 'CS_'+res.data.data.id,
                  "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
                  "location": data.location,
                  "clientLocation" : data.location,
                  "clientLocaton" : data.location,
                  "date": res.data.data.Date,
                  "startTime": data.startTime,
                  "endTime": data.endTime,
                  "clientId": data.clientId,
                  "notes": data.notes,
                  "serviceId": Number(data.serviceId),
                  "costPerHour": data.costPerHour,
                  "day" : moment(res.data.data.Date).format('dddd'),
                  "serviceName" : servicePos>=0?this.state.serviceSelectList[servicePos].label:"",
                  "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                  "isClientShift" : true,
                  "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                  "addExtraHourForInvoicing" :  data.addExtraHourForInvoicing,
                  "trackingItemID": data.trackingItemID
                })
                if(element==this.state.selectedShiftDate){
                  arr2.push({
                    "shiftId" : 'CS_'+res.data.data.id,
                    "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
                    "location": data.location,
                    "clientLocation" : data.location,
                    "clientLocaton" : data.location,
                    "date": res.data.data.Date,
                    "startTime": data.startTime,
                    "endTime": data.endTime,
                    "clientId": data.clientId,
                    "notes": data.notes,
                    "serviceId": Number(data.serviceId),
                    "costPerHour": data.costPerHour,
                    "day" : moment(res.data.data.Date).format('dddd'),
                    "serviceName" : servicePos>=0?this.state.supportServices[servicePos].supportService:"",
                    "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                    "isClientShift" : true,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "addExtraHourForInvoicing" :  data.addExtraHourForInvoicing,
                    "trackingItemID": data.trackingItemID
                  })
                }
  
                this.state.DatabaseData = arr;
                this.state.staffAppointments = arr2;
                this.setState({DatabaseData: arr, staffAppointments:arr2})
                pos++;
                this.manageFiltersAfterUpdate();
                
              
              this.saveAppointmentAlert();
            }
          }).catch(error => {
            pos++;
            this.setState({ savingData: false })
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
  
          })
      }
    })
  }
  handleSubmitEditedAppointment = (event) =>{
    this.setState({ savingData: true })
    event.preventDefault();
    const infor = {
      "staffId": this.state.staffId,
      "location": this.state.clientLocation,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "serviceId": this.state.clientServiceId,
      "notes": this.state.notes,
      "clientId": this.state.clientId,
      "costPerHour" : this.state.costPerHour,
      "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
      "trackingItemID" : this.state.trackingItemID
    };

    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${this.state.shiftId}`, infor)
      .then(res => {
        this.setState({ savingData: false, isSubmitingInProgress: false })
        if (res.status == 200) {
          var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.staffAppointments
            if (true) {
              
              const index1= arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              var staffShifts =arr[index1].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == this.state.shiftId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.shiftId);
              arr[index1].shifts[pos]={
                "staffId": this.state.staffId,
                "location": this.state.clientLocation,
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "serviceId": this.state.clientServiceId,
                "notes": this.state.notes,
                "clientId": this.state.clientId,
                "isClientShift": true,
                "day" :  this.state.editedAppointments.day,
                "clientName" : this.state.clientSelectedValue.label,
                "clientLocaton" :  this.state.clientLocation,
                "clientLocation" : this.state.clientLocation,
                "isApproved" :  this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "shiftId" : this.state.shiftId,
                "serviceName" : this.state.serviceSelectedValue.label,
                "costPerHour" : this.state.costPerHour,
                "isNotClient" : false,
                "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
                "duration" : this.calculateDuration({startTime : this.state.startTime, endTime : this.state.endTime}),
                "trackingItemID" :  this.state.trackingItemID
              };
              arr2[pos2]={
                "staffId": this.state.staffId,
                "location": this.state.clientLocation,
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "serviceId": this.state.clientServiceId,
                "notes": this.state.notes,
                "clientId": this.state.clientId,
                "isClientShift": true,
                "day" :  this.state.editedAppointments.day,
                "clientName" : this.state.clientSelectedValue.label,
                "clientLocaton" :  this.state.clientLocation,
                "clientLocation" : this.state.clientLocation,
                "isApproved" :  this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "shiftId" : this.state.shiftId,
                "costPerHour" : this.state.costPerHour,
                "serviceName" : this.state.serviceSelectedValue.label,
                "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
                "duration" : this.calculateDuration({startTime : this.state.startTime, endTime : this.state.endTime}),
                "trackingItemID" :  this.state.trackingItemID
              };
              
            }else{
              const index1 = arr.findIndex(x => x.staffId == this.state.selectedShiftDetails.staffId);
              const pos = arr[index1].shifts.findIndex(x => x.shiftId == this.state.editedAppointments.appointmentId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.editedAppointments.appointmentId);
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
              arr[index1].shifts=arr[index1].shifts.slice(0, pos).concat(arr.slice(pos+1, arr[index1].shifts.length));

              const indexTo=arr.findIndex(x => x.staffId == this.state.editedAppointments.staffId);
              arr[indexTo].shifts.push({
                "staffId": infor.staffId,
                "location": infor.location,
                "startTime": infor.startTime,
                "endTime": infor.endTime,
                "serviceId": infor.serviceId,
                "notes": infor.notes,
                "clientId": infor.clientId,
                "clientName" : this.state.selectedShiftDetails.clientName,
                "isClientShift": true,
                "day" :  this.state.selectedShiftDetails.day,
                "isApproved" :  this.state.selectedShiftDetails.isApproved,
                "clientLocaton" :  this.state.selectedShiftDetails.clientLocaton,
                "shiftId" : this.state.editedAppointments.appointmentId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
              });
            
            }
            this.setState({isSubmitingInProgress: false});
            
            this.setState({DatabaseData:arr, staffAppointments : arr2})
            this.manageFiltersAfterUpdate();
            this.saveAlert();

        }
      }).catch(error => {
        console.log(error)
        this.setState({ savingData: false, isSubmitingInProgress: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })

  }

  handleEditShift = (event) => {
    event.preventDefault();
    var url;
    var infor;
    this.setState({ isSubmitingInProgress: true });
      if(this.state.selectedStaffId==-1){
        infor = {
          "location": this.state.locationId,
          "Date": this.state.selectedShiftDate ,
          "startTime": this.state.startTime,
          "endTime": this.state.endTime,
          "breaking": this.state.breakMin,
          "notes": this.state.notes,
          "isActiveSleepOver" : this.state.isActiveSleepOver,
          "trackingItemID" : this.state.trackingItemID
        };
        url=`${GlobalFunctions.extractToken().API_URL}/shifts/editOpenShift?shiftId=${this.state.shiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`
      }else{
        infor = {
          "location": this.state.locationId,
          "Date": this.state.selectedShiftDate ,
          "startTime": this.state.startTime,
          "endTime": this.state.endTime,
          "breaking": this.state.breakMin,
          "notes": this.state.notes,
          "staffId": this.state.staffId,
          "isActiveSleepOver" : this.state.isActiveSleepOver,
          "trackingItemID" : this.state.trackingItemID
        };
        url=`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.shiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`
      }
      axios.post(url, infor)
        .then(res => {
          this.setState({ isSubmitingInProgress: false })
          if (res.status == 200) {
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts
            const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(this.state.locationId));

            if (true) {
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              var staffShifts =arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == this.state.shiftId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.shiftId);
              arr[index].shifts[pos]={
                "location": this.state.locationId,
                "locationId": this.state.locationId,
                "Date": this.state.selectedShiftDate,
                "day" : moment(this.state.selectedShiftDate).format('dddd'),       
                "endTime": this.state.endTime,
                "startTime": this.state.startTime,
                "breaking": this.state.breakMin,
                "notes": this.state.notes,
                "staffId": this.state.staffId,
                "shiftId" : this.state.shiftId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                "clientLocation" : this.state.companyLocations[locationPos].name,
                "isActiveSleepOver" : this.state.isActiveSleepOver,
                "isApproved" : this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "trackingItemID" : this.state.trackingItemID

              };
              arr2[pos2]={
                "location": this.state.locationId,
                "locationId": this.state.locationId,
                "Date": this.state.selectedShiftDate,
                "day" : moment(this.state.selectedShiftDate).format('dddd'),       
                "endTime": this.state.endTime,
                "startTime": this.state.startTime,
                "breaking": this.state.breakMin,
                "notes": this.state.notes,
                "staffId": this.state.staffId,
                "shiftId" : this.state.shiftId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                "clientLocation" : this.state.companyLocations[locationPos].name,
                "isActiveSleepOver" : this.state.isActiveSleepOver,
                "isApproved" : this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "trackingItemID" : this.state.trackingItemID

              };
              
            }
            
            this.setState({isSubmitingInProgress: false});
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
            this.saveAlert();
          }


        }).catch(error => {
          console.log(error)
          this.setState({ isSubmitingInProgress: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    

  }

  showModal = () =>{
    this.setState({ ShowModal: true });
  }

  hideModal = () => {
    this.setState({ ShowModal: false });
  }
  showAddModal = () =>{
    this.setState({ ShowAddModal: true });
  }
  showAddAppointmentModal = (event) => {
    var dates=[]
    var pos=0;
    while(moment(event).add(pos,'days').format('MM/DD/YYYY')<moment(this.state.weekEnd).format('MM/DD/YYYY')){
      pos++;
      dates.push(moment(event).add(pos,'days').format('MM/DD/YYYY'));
    }
    this.setState({ ShowAddAppointmentModal: true, remainingWeekDays: dates});
  }
  showCopyShiftsModal = () => {
    this.setState({ ShowCopyShiftsModal: true });
  }

  hideCopyShiftsModal = () => {
    this.setState({ ShowCopyShiftsModal: false });
  }
  hideAddModal = () => {
    this.setState({ ShowAddModal: false });
  }
  hideAddAppointmentModal = () =>{
    this.setState({ staffAppointments: [] })
    this.setState({ ShowAddAppointmentModal: false });
  }
  showOpenShiftsModal = () => {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal = () => {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle = (event) => {
    this.setState({ [event.name]: event.value })
  }


  //AN HTTP REQUEST TO THE getAllShifts-endpoint
   getShifts = (event) =>{
    this.setState({ loadingData: true })
    var shiftsList = [];
    var sortedShiftList = [];
    var openShiftObject;
    var staffList = [];
    var temp = [];
    var finalData = [];
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(shifts => {
      this.setState({ shifts: shifts.data.data1 });
      //FETCHING ALL STAFF FROM API
      url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?weekStart=${event.start}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(openShift => {
          shiftsList = shifts.data.data;
          staffList = staff.data;
          
          //SELECTING MANAGERS FOR FILTERING PURPOSE
          this.setState({managersSeleectList :  []});
          this.state.managersSeleectList.push({label : 'All Managers', value : 0})
          staffList.forEach(element=>{
            if (element.access_level==3) {
              this.state.managersSeleectList.push({
                label : element.lastname+' '+element.firstname, 
                value : element.id
              })
            }
          })
          this.setState({ staffList: staffList });
          //ADDING OPEN SHIFTS FOR DISPLAY;       
          openShiftObject = { staffId: -1, staffName: 'Open Shifts', shifts: openShift.data };
          sortedShiftList.push(openShiftObject);
          sortedShiftList =sortedShiftList.concat(shifts.data.data)
          this.setState({ DatabaseData: sortedShiftList, shiftsList: sortedShiftList, loadingData: false, totalShifts: { totalShifts: shifts.data.counters.totalShifts, totalApproved: shifts.data.counters.totalApproved, totalAdminConfirmed: shifts.data.counters.totalConfirmed, totalCompleted: shifts.data.counters.totalCompleted }})
          return sortedShiftList;
        }).catch(error => {

        })
      }).catch(error => {

      })
    }).catch(error => {

    })
  }

  handleWeekSubmit = () => {
    var staffIds=[]
    this.state.DatabaseData.forEach(element => {
      staffIds.push(Number(element.staffId));
    });
    if (this.state.selectedStaffIds.length==0) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?start=${this.state.weekStart}&end=${this.state.weekEnd}&staffIds=${staffIds}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1,{copiedWeekShifts:this.state.copiedWeekShifts, staffIds : staffIds} ).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
          this.setState({selectedStaffIds:[]})
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
    }else{
      this.state.selectedStaffIds.forEach(element=>{
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?staffId=${element}&start=${this.state.weekStart}&end=${this.state.weekEnd}&staffIds=${staffIds}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1, {copiedWeekShifts:this.state.copiedWeekShifts, staffIds : staffIds}).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
      })
    }
    

    this.setState({selectedStaffIds:[]});
    this.setState({ copiedWeekShifts: [] });
  }
  getClients = () => {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
      client.data.forEach(element=>{
        this.state.clientSelectList.push({
          label : element.clientname,
          value : element.id
        })
      })
    })

  }

  getAppointments = (event) => {
    this.setState({ loadingData: true })

    if (event.isCordinator) {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/getAppointments?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&shiftId=${event.shiftId}`;
      axios.get(url1).then(client => {
        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    } else {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staffclientshift?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}&date=${this.state.selectedShiftDate}`;
      axios.get(url1).then(client => {
        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    }
  }

  getParticipantServices = (event)=>{
    this.setState({ supportServicesPerClient: [], serviceSelectedValue :{label : 'Loading Client Services from server...', value : 0} });
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/participantServices?clientId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServicesPerClient: client.data, serviceSelectedValue : null});
      client.data.forEach(element =>{
        this.state.serviceSelectList.push({
          value : element.supportServiceId,
          label : element.supportService
        })
      })
    }).catch(error =>{
      this.setState({serviceSelectedValue :{label : 'An error occured, check your connection & reselect client', value : 0}  })
    })
  }

  deleteAppointmentAlert=(event)=>{
    var BreakException = {};
    swal({
      title: "Selected Items will be Deleted",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      try {
        var pos =0;
        event.forEach(data=>{
          const completeStatus=data.isComplete;
          if(completeStatus) throw BreakException;
          pos++;
          if(pos==event.length){
            this.deleteShift(event);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Completed and Confirmed shifts",
            text: "NOTE Completed and Confirmed  will not be Deleted",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.deleteShift(event);
           }
          })
      }
     }
    })
    
}

  deleteShift = (ids) =>{
    this.setState({ savingData: true, loadingData : true })
    var arrypos=0;
    var success=0;
    this.state.selectedListClientAppointmentIds.forEach(data=>{
      var id = data.shiftId
      if(!isNaN(id)){
        if (this.state.selectedStaffId == -1) {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteOpenShift?shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            this.state.totalShifts.totalShifts-=1;
            arrypos++; 
            success++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            this.setState({ savingData: false })
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts;
  
            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == id);
            const pos2 = arr2.findIndex(x => x.shiftId == id);
            // Checking for complete status in order to delete
            const completeStatus=!(staffShifts[pos].isComplete||staffShifts[pos].isCompleted);
            if(staffShifts[pos].isApproved){            
              this.state.totalShifts.totalApproved-=1;
            }
            if(index>-1&&pos2>-1){
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
            }
            
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
            
          }).catch(error => {
            arrypos++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
          })
    
        } else {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${id}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            this.state.totalShifts.totalShifts-=1;
            arrypos++; 
            success++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            this.setState({ savingData: false })
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts;
  
            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == id);
            const pos2 = arr2.findIndex(x => x.shiftId == id);

            // Checking for complete status in order to delete
            const completeStatus = !(staffShifts[pos].isComplete ||staffShifts[pos].isCompleted);
            if(staffShifts[pos].isApproved){            
              this.state.totalShifts.totalApproved-=1;
            }
            if(pos2>-1&&index>-1){
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
            arr2=this.state.locationShifts.slice(0, pos2).concat(this.state.locationShifts.slice(pos2+1, this.state.locationShifts.length));
            }
            
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
    
          }).catch(error => {
            arrypos++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
          })
        }
      }else{
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteAppointment?appointmentId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          this.state.totalShifts.totalShifts-=1;
          arrypos++; 
          success++;
          this.deletedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
          this.setState({ savingData: false });
          var arr =[];
          var arr2 = [];
          arr= this.state.DatabaseData;
          arr2=this.state.staffAppointments;
          const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
          const staffShifts =arr[index].shifts;
          const pos = staffShifts.findIndex(x => x.shiftId == id);
          const pos2 = arr2.findIndex(x => x.shiftId == id);

          // Checking for complete status in order to delete
          const completeStatus = !(staffShifts[pos].isComplete||staffShifts[pos].isCompleted);
          if(staffShifts[pos].isApproved){            
            this.state.totalShifts.totalApproved-=1;
          }
          if (index>-1&&pos2>-1) {
            arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
            arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
          }
          this.state.DatabaseData = arr;
            this.state.staffAppointments = arr2;
            this.manageFiltersAfterUpdate();
        }).catch(error => {
          arrypos++;
          this.deletedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
        })
      }
    })

  }

  deletedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is deleted successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }
  

  handleApproveTimeSheetsAlert =()=>{
    swal({
      title: "The selected shifts will be approved",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
     if(willDelete){
      this.handleApproveAppointments(this.state.selectedListClientAppointmentIds);
     }
    })
  }

  handleApproveAppointments = (ids) => {
    const locationShiftsIDs = ids.filter(function (x) {return !(isNaN(x.shiftId));});
    const clientShiftsIDs = ids.filter(function (x) {return isNaN(x.shiftId);});
    var url1= `${GlobalFunctions.extractToken().API_URL}/shifts/approveAppointment?ids=${clientShiftsIDs}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`;
    var url2 = `${GlobalFunctions.extractToken().API_URL}/shifts/approve?ids=${locationShiftsIDs}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`;
      axios.post(url1, clientShiftsIDs).then(client1 => {
        axios.post(url2, locationShiftsIDs).then(client => {
          this.setState({selectedListClientAppointmentIds : []})
          var arr =[];
          var arr2 = [];
          var arr3 = [];
          arr= this.state.DatabaseData;
          arr2=this.state.staffAppointments;
          arr3=this.state.locationShifts
          const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
          const staffShifts =arr[index].shifts;
          clientShiftsIDs.forEach(element=>{
            const pos = staffShifts.findIndex(x => x.shiftId == element.shiftId);
            const pos2 = arr2.findIndex(x => x.shiftId == element.shiftId);
            staffShifts[pos].isApproved=true;
            arr2[pos2].isApproved=true;
          })
          locationShiftsIDs.forEach(element=>{
            const pos = staffShifts.findIndex(x => x.shiftId == element.shiftId);
            const pos2 = arr3.findIndex(x => x.shiftId == element.shiftId);
            staffShifts[pos].isApproved=true;
            arr3[pos2].isApproved=true;
          })
          const successCount = Number(client.data.successCount+client1.data.successCount);
          this.state.totalShifts.totalApproved+=successCount;
          this.setState({DatabaseData : arr, staffAppointments : arr2,locationShifts : arr3,loadingData : false});
          this.approvedShiftAlert(Number(clientShiftsIDs.length+locationShiftsIDs.length), successCount);
          
          this.manageFiltersAfterUpdate();
        })
        
      }).catch(error=>{
        swal("Failed to approve the shift(s)", " ", "warning");
        console.log(error)
      });
  }

  approvedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is Successfully approved`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }

  confirmAppointmentAlert_01=(event)=>{
    swal({
      title: "Selected shifts will be confirmed",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      var BreakException = {};
      try {
        var pos =0;
        this.state.selectedListClientAppointmentIds.forEach(data=>{
          if(!(data.isComplete||data.isConcelled==true)) throw BreakException;
          pos++;
          if(pos==this.state.selectedListClientAppointmentIds.length){
            this.handleConfirmAppointments(this.state.selectedListClientAppointmentIds);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Incomplete Shift",
            text: "Those shifts will won't be confirmed. Cancelled Shifts will become Open Shifts",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleConfirmAppointments(this.state.selectedListClientAppointmentIds);
           }
          })
      }
     }
    })
      
  }


  handleConfirmAppointments = (ids) => {
    var arrypos=0;
    var success=0;
    this.setState({ savingData: true, loadingData : true })

    ids.forEach(data=>{
      var element = data.shiftId;
      if(isNaN(element)){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirmAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
            arrypos++; 
            success++;
            if(ids.length==arrypos){
              this.state.totalShifts.totalAdminConfirmed+=success;
              this.setState({loadingData : false})
            }
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.staffAppointments

            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == element);
            const pos2 = arr2.findIndex(x => x.shiftId == element);
            if(staffShifts[pos].isComplete||staffShifts[pos].isCompleted||staffShifts[pos].isConcelled){
              staffShifts[pos].isConfirmed=true;
              arr2[pos2].isConfirmed=true;
            }
           
            if(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled){
              staffShifts[pos].isApproved=false;
              arr2[pos2].isConfirmed=false;
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
              const openShiftPosition=arr.findIndex(x => x.staffId == -1);
              arr[openShiftPosition].shifts.push(staffShifts[pos]);
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
            }
            
            this.setState({ savingData: false, DatabaseData : arr, staffAppointments : arr2});
            this.state.staffAppointments=arr2;
            this.confirmedShiftAlert(ids.length, success);
            this.manageFiltersAfterUpdate();
            
          
        }).catch(error => {
          this.setState({ savingData: false })
          arrypos++;
          this.confirmedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.state.totalShifts.totalAdminConfirmed+=success;
            this.setState({loadingData : false})
          }
        })
      }else{
        
            var id = data.shiftId;
            var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/adminConfirm?shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
            axios.get(url1).then(client => {
              arrypos++; 
              success++;
              if(ids.length==arrypos){
                this.state.totalShifts.totalAdminConfirmed+=success;
                this.setState({loadingData : false})
              }
              this.setState({ savingData: false })
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              const staffShifts =  arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == id);
              arr2=this.state.locationShifts
              const pos2 = arr2.findIndex(x => x.shiftId == id);
              if(staffShifts[pos].isConcelled||staffShifts[pos].isComplete||staffShifts[pos].isCompleted){
                staffShifts[pos].isConfirmed=true;
                arr2[pos2].isConfirmed=true;
              }
             
              if(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled){
                staffShifts[pos].isApproved=false;
                arr2[pos2].isConfirmed=false; 
                arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
                const openShiftPosition=arr.findIndex(x => x.staffId == -1);
                arr[openShiftPosition].shifts.push(staffShifts[pos]);
                arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
              }
              
              this.setState({DatabaseData : arr, locationShifts : arr2})
              this.confirmedShiftAlert(ids.length, success);
              this.manageFiltersAfterUpdate();
            }).catch(error =>{
              arrypos++;
              this.confirmedShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.state.totalShifts.totalAdminConfirmed+=success;
                this.setState({loadingData : false})
              }
              this.setState({ savingData: false })
            })
          
      }
      
    })

  }

  confirmedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is successfully confirmed`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }
 
  cancelAppointmentAlert=(event)=>{
    var BreakException = {};
    swal({
      title: "The selected shifts will be cancelled",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      try {
        var pos =0;
        this.state.selectedListClientAppointmentIds.forEach(data=>{
          if(data.isComplete==true||data.isCompleted==true) throw BreakException;
          pos++;
          if(pos==this.state.selectedListClientAppointmentIds.length){
            this.handleCancelClientAppointmentShift(this.state.selectedListClientAppointmentIds);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected completed or confirmed  shifts",
            text: "The will NOT be Cancelled",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleCancelClientAppointmentShift(this.state.selectedListClientAppointmentIds);
           }
          })
      }
     }
    })
    
}
  handleCancelClientAppointmentShift = (ids) =>{
    var pos = 0;
    var arrypos=0;
    var success=0;
    this.setState({loadingData : true})
    ids.forEach(data=>{
      var element= data.shiftId
      if(isNaN(element)){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          arrypos++; 
          success++;
          this.cancelledShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
          var arr =[];
          var arr2 = [];
          arr= this.state.DatabaseData;
          arr2=this.state.staffAppointments

          const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
          const staffShifts =arr[index].shifts;
          const pos = staffShifts.findIndex(x => x.shiftId == element);
          const pos2 = arr2.findIndex(x => x.shiftId == element);
          
          if(!(staffShifts[pos].isComplete==true || staffShifts[pos].isCompleted==true)){
            staffShifts[pos].isConcelled=true;
            arr2[pos2].isConcelled=true
          }
          
          this.state.DatabaseData = arr;
          this.state.staffAppointments = arr2;
          this.manageFiltersAfterUpdate();
        }).catch(error => {
          this.setState({ savingData: false })
          arrypos++;
          this.cancelledShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
        })
      }else{
          var id = data.shiftId;
          if(!isNaN(id)){
            var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancel?shiftId=${id}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
            axios.get(url1).then(client => {
              arrypos++; 
              success++;
              this.cancelledShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              arr2=this.state.locationShifts;
    
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              const staffShifts =arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == id);
              const pos2 = arr2.findIndex(x => x.shiftId == id);
              if(!(staffShifts[pos].isComplete == true||staffShifts[pos].isCompleted == true)){
                arr[index].shifts[pos].isConcelled=true;
                arr2[pos2].isConcelled=true
              }
             
                this.setState({DatabaseData : arr, locationShifts : arr2})
                this.manageFiltersAfterUpdate();
            }).catch(error => {
              this.setState({ savingData: false })
              arrypos++;
              this.cancelledShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
            })
          }
      }
      
    })
    
  }
  cancelledShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is cancelled successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }

  getDayLocationShifts = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/dayShifts?staffId=${this.state.selectedStaffId}&date=${this.state.selectedShiftDate}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({locationShifts: res.data});
    }).catch(error => {
      this.setState({ savingData: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }
  calculateWeekRange = () => {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday()) - 1
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
  
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end });
    this.getShifts({ start: start, end: end });
  }

  getNextWeek = () => {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  getPreviousWeek = () => {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }
  calculateDuration = (params) =>{
    var startTime = date.parse(params.startTime, 'HH:mm:ss A', true);
    var endTime = date.parse(params.endTime, 'HH:mm:ss A', true);
    var duration = date.subtract(endTime, startTime).toHours();
    duration = parseFloat(duration<0?24+duration:duration).toFixed(2)
    return Number(duration);
}
  pasteShift = (date, selectedStaffId) => {
   this.state.coppiedShistDetails.locationShifts.forEach(element=>{
      const infor = {
        "staffId": Number(selectedStaffId),
        "location": element.locationId,
        "Date": date,
        "startTime": element.startTime,
        "endTime": element.endTime,
        "breaking": element.breaking,
        "notes": element.notes,
        "allowance": 0,
        "isActiveSleepOver" : element.isActiveSleepOver,
        "trackingItemID" : element.trackingItemID
      };
      if (Number(selectedStaffId) == -1) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.state.totalShifts.totalShifts+=1;
            var arr =[];
              arr= this.state.DatabaseData;
                const index= arr.findIndex(x => x.staffId == Number(-1));
                const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                arr[index].shifts.push({
                  "shiftId" : res.data.data.shiftId,
                  "staffId": -1,
                  "locationId" : this.state.companyLocations[locationPos].id,
                  "location" : this.state.companyLocations[locationPos].id,
                  "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                  "date": date,
                  "day":moment(date).format('dddd'),
                  "startTime": res.data.data.startTime,
                  "endTime": res.data.data.endTime,
                  "duration" : this.calculateDuration({startTime : res.data.data.startTime, endTime : res.data.data.endTime}),
                  "notes": res.data.data.notes,
                  "breaking": res.data.data.breaking,
                  "isClientShift" : false,
                  "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                  "trackingItemID" : res.data.data.trackingItemID
                })
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                
                this.manageFiltersAfterUpdate();
  
          }).catch(error => {
            console.log(error)
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
          });
        } else {
          axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
            .then(res => {
              this.state.totalShifts.totalShifts+=1;
              var arr =[];
              arr= this.state.DatabaseData;
                const index= arr.findIndex(x => x.staffId == Number(res.data.data.staffId));
                const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                arr[index].shifts.push({
                  "shiftId" : res.data.data.shiftId,
                  "staffId": res.data.data.staffId,
                  "locationId" : this.state.companyLocations[locationPos].id,
                  "location" : this.state.companyLocations[locationPos].id,
                  "clientLocation" : this.state.companyLocations[locationPos].name,
                  "date": date,
                  "day":moment(date).format('dddd'),
                  "startTime": element.startTime,
                  "endTime": element.endTime,
                  "duration" : this.calculateDuration({startTime : element.startTime, endTime : element.endTime}),
                  "notes": element.notes,
                  "breaking": element.breaking,
                  "isClientShift" : false,
                  "isActiveSleepOver" : element.isActiveSleepOver,
                  "trackingItemID" : res.data.data.trackingItemID
                })
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                this.manageFiltersAfterUpdate();
                
            }).catch(error => {
              console.log(error)
              let errorObject = JSON.parse(JSON.stringify(error));
              this.networkFailureAlert(errorObject.message)
            })
        }

   })
   this.state.coppiedShistDetails.appointments.forEach(element=>{
     if(isNaN(element.shiftId)){
      const infor = {
        "staffId": Number(selectedStaffId)>0?Number(selectedStaffId):null,
        "location": element.clientLocaton,
        "date": date,
        "startTime": element.startTime,
        "endTime": element.endTime,
        "clientId": Number(element.clientId),
        "notes": element.notes,
        "serviceId": Number(element.serviceId),
        "costPerHour": element.costPerHour,
        "addExtraHourForInvoicing" : element.addExtraHourForInvoicing,
        "trackingItemID" : element.trackingItemID
      };
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/appointStaff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.state.totalShifts.totalShifts+=1;
            this.setState({ savingData: false });
            if (res.status == 201) {
              var arr =[];
              arr= this.state.DatabaseData;
                var index=arr.findIndex(x => x.staffId == Number(selectedStaffId));
                const clientPos=this.state.clients.findIndex(x => x.id == Number(res.data.data.clientId));
                const servicePos=this.state.supportServices.findIndex(x => x.supportServiceId == Number(res.data.data.serviceId));
                arr[index].shifts.push({
                  "shiftId" : 'CS_'+res.data.data.id,
                  "staffId": res.data.data.staffId==null? -1 :res.data.data.staffId,
                  "location": res.data.data.locationId,
                  "locationId": res.data.data.locationId,
                  "clientLocation" : res.data.data.location,
                  "clientLocaton" : res.data.data.location,
                  "date": res.data.data.Date,
                  "startTime": res.data.data.startTime,
                  "endTime": res.data.data.endTime,
                  "duration" : this.calculateDuration({startTime : res.data.data.startTime, endTime : res.data.data.endTime}),
                  "clientId": Number(res.data.data.clientId),
                  "notes": res.data.data.notes,
                  "serviceId": Number(res.data.data.serviceId),
                  "costPerHour": res.data.data.costPerHour,
                  "day" : moment(res.data.data.Date).format('dddd'),
                  "serviceName" : servicePos>=0?this.state.supportServices[servicePos].supportService:"",
                  "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                  "isClientShift" : true,
                  "addExtraHourForInvoicing" : res.data.data.addExtraHourForInvoicing,
                  "trackingItemID" : res.data.data.trackingItemID
                });
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                
                this.manageFiltersAfterUpdate();
              this.saveAppointmentAlert();
            }
          }).catch(error => {
            console.log(error)
            this.setState({ savingData: false })
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
  
          })
     }
   })

  }


  calculatePasteWeek = () => {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    var arr = [];
    for (let index = 0; index < 9; index++) {
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
      arr.push({ weekStart: start, weekEnd: end });
      start = moment(end).add(1, 'days').format('MM-DD-YYYY');
    }
    this.setState({ getNextWeeks: arr });

  }

  cancellShift = () =>{
    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${98}`, {status : 0})
    .then(res => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      if (res.status == 200) {
        this.getDayLocationShifts();
        this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }

    }).catch(error => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)

    })
  }

  componentDidMount() {
    GlobalFunctions.extractToken();
    this.calculateWeekRange();
    this.setState({locationSelectList :  []});
    GlobalFunctions.getCompanyLocations().then(res=>{
      this.setState({companyLocations : res});
      this.state.locationSelectList.push({label : 'All Locations', value : 0})
      res.forEach(element => {
        this.state.locationSelectList.push({
          value : element.id,
          label : element.name
        })
      });
    })
    this.getLocationUserData();
    this.getManagerUserData();
    this.getClients();
  }

  checkStaffAvailability =  (event) =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/checkStaffAvailability?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&date=${event.date}&staffId=${event.staffId}`
    axios.get(url).then(result =>{
      this.setState({isStaffAvailable:result.data});
    }).catch(error =>{
      swal("Error when checking for staff availability!", " ", "warning");
      console.log(error);
    })
  }

  networkFailureAlert = (event) => {
    swal(event, " ", "warning")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAlert = () => {
    swal("successfully saved!", " ", "success")
      .then(() => {
        
        this.hideAddModal();
        this.hideModal();
      })
  }
  confirmAppointmentAlert = () => {
    swal("successfully confirmed!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAppointmentAlert = () => {
    swal("successfully saved!", " ", "success")
      .then(() => {
      })
  }
  weekCopyAlert = () => {
    swal("Week successfully copied!", " ", "success")
      .then(() => {
        this.hideCopyShiftsModal();
        this.hideModal();
      })
  }
  approveAlert = () => {
    swal("successfully approved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  deleteSaveAlert = () => {
    swal("successfully deleted!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }


  deleteAlert = (event) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.deleteShift(event);
        }
      })
  }

  dayShiftAnalyser = (counters, element) =>{
    var counters=counters;
    if(!element.isApproved){
      counters.notApproved+=1
    }
    if(element.isApproved && !element.isComplete && !element.isConfirmed){
      counters.approved+=1
    }
    if(element.isComplete && !element.isConfirmed){
      counters.completed+=1;
    }
    if(element.isConfirmed){
      counters.confirmed+=1;
    }
    return counters;
  }

  popoverHoverFocus = (event) => {
    return (
      <Popover id="popover-trigger-hover-focus" title="Popover bottom">
        <table class="table table-bordered">
          {
            event.map((element) => {
              return (
                <tr>
                  <td>{element.clientName}</td>
                 {element.isNotClient || element.isActiveSleepOver==''?'' : <td>{element.isActiveSleepOver}</td>} 
                  <td>{element.startTime} - {element.endTime}</td>
                </tr>
              )

            })
          }
        </table>
      </Popover>
    )
  }

  render() {

    const columns = [{
      Header: () => (
        <div>
          <form id="es">
            <input
              name="searchInput"
              defaultValue={this.state.searchInput}
              onChange={(event)=>{
                document.getElementById("input1").focus();
                this.setState({lastFilterApplied : 'Staff'});
                this.handleChangeStaffNameFilter(event);
                
              }}
              id="input1"
              onMouseOver={() => {
                document.getElementById("input1").focus();
              }}
              className="form-control seach-bar"
              type="text"
              placeholder="Search"
              style={{
                height: "unset",
                lineHeight: 3
              }} />
          </form>
        </div>
      ),
     
      Cell : row =>{
        return(
          <div
            onClick={()=>{
              this.state.selectedStaffIds.push(row.original.staffId)
            }}
          >
              <div class="form-check">
              <input name="staffList" class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
              <label class="form-check-label" for="flexCheckChecked">
                {row.original.staffName}
              </label>
            </div>
          </div>
        )
      },
      minWidth: 260,
      sortable: false

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Monday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(0, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {

        const dayPosition = 0;
        const dayName = 'Monday';
        var tocalShiftsHours = 0;
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
        var index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element)
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
            if (!clientList.includes(element.clientName)) {
              clientList.push({
                clientName : element.clientName?element.clientName: element.clientLocation,
                startTime : element.startTime,
                endTime : element.endTime,
                isNotClient : element.clientName? true : false,
                isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
              });
            }
          });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>
        } else {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true});
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Tuesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(1, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      id  : "staffId",
      minWidth: 150,
      sortable: false,
      Cell: row => {
        const dayPosition = 1;
        const dayName = 'Tuesday';
        var tocalShiftsHours = 0;
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
           
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element)
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {
          
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      id: 'starting_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Wednesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(2, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth : 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {
        const dayPosition = 2;
        const dayName = 'Wednesday';
        var tocalShiftsHours = 0;
     
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element)
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn  text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                             this.checkStaffAvailability({date:today, staffId: e.staffId})
                            this.showAddAppointmentModal(today);
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {
          
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      id: 'ending_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Thursday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(3, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth : 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {
        const dayPosition = 3;
        const dayName = 'Thursday';
        var tocalShiftsHours = 0;
     
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters= this.dayShiftAnalyser(counters, element);
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {      
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Friday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(4, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth : 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {
        const dayPosition = 4;
        const dayName = 'Friday';
        var tocalShiftsHours = 0;
     
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element)
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white btn-outline-warning text-white btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {
          
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      id: 'ending_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Saturday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(5, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth : 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {
        const dayPosition = 5;
        const dayName = 'Saturday';
        var tocalShiftsHours = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
     
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == dayName) {
              tocalShiftsHours=parseFloat(tocalShiftsHours+element.duration).toFixed(2);
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element);
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {
          
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >
                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Sunday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(6, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth : 150,
      id  : "staffId",
      sortable: false,
      Cell: row => {
        const dayPosition = 6;
        const dayName = 'Sunday';
        var tocalShiftsHours = 0;
     
        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
        const today = moment(weekStart).add(dayPosition, 'days').format('MM-DD-YYYY')
        var e = row.original;
        var array = [];
        var appointments = [];
        var display = [];
        var locationShifts = [];
        array = e.shifts;
        var index = 0;
        var counters = {
          notApproved : 0,
          approved : 0,
          completed: 0,
          confirmed : 0
        }
      
        index = array.findIndex(x => x.day === dayName);
        if (index >= 0) {
          array.forEach(element => {
            
            if (element.day == dayName) {
              tocalShiftsHours=tocalShiftsHours+element.duration;
              if (isNaN(element.shiftId)) {
                appointments.push(element)
              }else{
                locationShifts.push(element)
              }
              counters = this.dayShiftAnalyser(counters, element);
              display.push(element);
            }
          });
          var clientList = [];
          var data = appointments.concat(locationShifts);
          data.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push({
                  clientName : element.clientName?element.clientName: element.clientLocation,
                  startTime : element.startTime,
                  endTime : element.endTime,
                  isNotClient : element.clientName? true : false,
                  isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
                });
              }
            });
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {
                  this.setState({ clientList: clientList });
                }
                }
              >
                <a 
                className={
                  counters.approved/data.length==1?"btn text-white btn-success btn-block btn-sm mb-1 text-left":
                  counters.completed/data.length==1?"btn text-white btn-info btn-block bg-gradient btn-sm mb-1 text-left":
                  counters.confirmed/data.length==1?"btn text-white btn-warning btn-block btn-sm mb-1 text-left":
                  "btn text-white btn-secondary  btn-block btn-sm mb-1 text-left"
                  }>
                  <div className="row">
                    <div className="col-md-12">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{appointments.length+locationShifts.length}  shifts &nbsp;</span></small>
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {tocalShiftsHours} Hour Shift </span></small>
                    </div>

                    <div className="col-md-6 ">
                    <button 
                      onClick={(event)=>{
                        this.setState({coppiedShistDetails : {locationShifts : locationShifts, appointments : appointments}})
                      }}
                    className="btn text-white btn-outline-warning btn-sm "><small>Copy</small></button>
                      <div className="btn-group">
                        <button
                          className="btn text-white text-white btn-outline-warning btn-sm "
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({ 
                              isCordinator: false, 
                              clientShiftOptionsShow:true,
                              selectedStaffId: e.staffId,
                              selectedShiftDate: today,
                              staffAppointments: appointments,
                              locationShifts : locationShifts,
                              selectedStaffName: e.staffName,
                              hideLocationShiftInputFields : true,
        
                             })
                            this.showAddAppointmentModal(today);
                            this.checkStaffAvailability({date:today, staffId: e.staffId})
                          }}
                        >
                          <small>Open</small>
                        </button>
                        <button
                            onClick={() => {
                              this.pasteShift( today, e.staffId );
                            }}

                            className="btn text-white btn-outline-warning btn-sm" 
                          >
                            <small>Paste</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

        } else {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button 
                      onClick={() => {
                        this.setState({ staffAppointments: [], isCordinator: false, clientShiftOptionsShow:true})
                        var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                        this.setState({ selectedStaffId: e.staffId, selectedStaffName: e.staffName,selectedShiftDate: today,hideLocationShiftInputFields : true, clientShiftOptionsShow:false, locationShifts : [], staffAppointments : [] });
                        this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: e.staffId})
                      }}
                     class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"  aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                  </div>

                  <button
                      onClick={() => {
                        this.pasteShift( today, e.staffId );
                      }}

                      className="btn  btn-outline-warning btn-full btn-sm"
                    >
                      Paste
                  </button>

                </button>

              </div>
            )

        }
      }
    }]

    const tableAppointments = [
      {
        Header : () =>(
          <>
            <button 
              className="btn text-white btn-warning" 
              type="button"
              onClick = {()=>{
                var arr = [];
                const data = this.state.staffAppointments.concat(this.state.locationShifts);
                arr = this.state.selectedListClientAppointmentIds;
                if(arr.length==0){
                  data.forEach(element => {
                    arr.push(element);
                  });
                }else{
                  arr = [];
                }
                this.setState({ selectedListClientAppointmentIds : arr})
              }}
              >&#10003;</button>
            </>
        ),
        Cell: row => {

          return (
            <div
            >
              <input
                onClick={(event) => {
                  var arr = [];
                  arr = this.state.selectedListClientAppointmentIds;
                  const index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                  if (index >= 0) {
                    arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                  } else {
                    arr.push(row.original);
                  }
                  this.setState({selectedListClientAppointmentIds:arr});
                }
                }
                type="checkbox" className="ml-2" 
                checked={ this.state.selectedListClientAppointmentIds.some(item=>item.shiftId==row.original.shiftId) ? true : false}
                >
              </input>
            </div >
          )
        },
        maxWidth : 50,
        sortable : false    
      },
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Client name
          </button>
        ),
        maxWidth: 160,
        sortable: false,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.clientName}</del>
              :
              <div>{row.original.clientName}</div>
          )
        }

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        maxWidth: 70,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.startTime}</del>
              :
              <div>{row.original.startTime}</div>
          )
        }

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        maxWidth: 70,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.endTime}</del>
              :
              <div>{row.original.endTime}</div>
          )
        }
      },{

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'clientLocaton',
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.clientLocaton||row.original.clientLocation}</del>
              :
              <div>{row.original.clientLocaton||row.original.clientLocation}</div>
          )
        }

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.notes}</del>
              :
              <div>{row.original.notes}</div>
          )
        }
      }, 
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Status
          </button>
        ),
        maxWidth : 110,
        sortable: false,
        Cell: row => {
          return (
            <div className="row ml-1">
              {
                row.original.isApproved&&!row.original.isComplete ? 
                  <div>
                    <span
                      class="badge badge-success"
                    >
                      Approved 
                    </span>
                  </div>
                  :
                  ''
              }
              {
                row.original.isComplete&&!row.original.isConfirmed ? 
                <div>
                  <span
                  class="badge badge-info "
                >
                  <small>Completed</small>
                </span>
                </div>
                  :
                  ""
              }
              {
                row.original.isConfirmed ?
                  <div>
                    <span
                    class="badge badge-warning"

                  >
                    <small>Confirmed</small>
                  </span>
                  </div>
                  :
                  ""
              }
              <a className="glyphicon glyphicon-edit" onClick={()=>this.setState({editedAppointments: row.original, trackingItemID : row.original.trackingItemID, locationShiftEditModal : true, clientServiceId : row.original.serviceId,serviceSelectedValue : {label: row.original.serviceName, value : 0},clientLocation : row.original.clientLocaton, costPerHour : row.original.costPerHour, clientSelectedValue : {label : row.original.clientName, value : 0}, locationId: row.original.locationId, breakMin : row.original.breaking, shiftId: row.original.shiftId, ShowAddAppointmentModal : false, isActiveSleepOver : row.original.isActiveSleepOver, startTime : row.original.startTime, endTime : row.original.endTime, notes : row.original.notes, staffId: row.original.staffId, clientId: row.original.clientId?row.original.clientId:0, addExtraHourForInvoicing : row.original.addExtraHourForInvoicing})}  href="#">Edit</a>
              
            </div>
          )
        }
      }
    ]
    
    return (
      <div >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <div style={{"width":"13%"}}>
            <Select
              value={this.state.lastFilterApplied=='Manager'?this.state.filterSchedulesByManagerValue:{label :'Filter By Manager', value : 0}}
              onChange={(event)=>{
                this.state.userManagerData.forEach(element=>{
                  if(event.value==element.managerId){
                    this.state.staffUnderSelectedFilter.push(element.staffManaged);
                  }
                })
                this.setState({lastFilterApplied : 'Manager', searchInput : '',  selectedFilterItermId : event.value});
                this.handleChangeManagerIdFilter(event);
              }}
              options={this.state.managersSeleectList}
            /> 
          </div>
          <div style={{"width":"13%"}}>
            <Select
              value={this.state.lastFilterApplied=='Location'?this.state.filterStaffByLocationValue:{label :'Filter By Location', value : 0}}
              onChange={(event)=>{
                this.state.userLocationData.forEach(element=>{
                  if(event.value==element.propertId){
                    this.state.staffUnderSelectedFilter.push(element.staffId);
                  }
                })
                this.setState({lastFilterApplied : 'Location', searchInput : '', selectedFilterItermId : event.value, address : event.value});
                this.handleChangeLocationIdFilter(event);
              }}
              options={this.state.locationSelectList}
            /> 
          </div>
          
          <div className="btn-toolbar mb-2 mb-md-0">
          <div type="button" class="btn btn-outline-primary btn-sm disabled">
             {this.state.totalShifts.totalAdminConfirmed} Confirmed
          </div>
          <button type="button" class="btn btn-outline-secondary btn-sm disabled">{this.state.totalShifts.totalCompleted} Completed</button>
          <button type="button" class="btn btn-outline-success btn-sm disabled">{this.state.totalShifts.totalApproved} Approved</button>
          <button type="button" class="btn btn-outline-primary btn-sm disabled">{this.state.totalShifts.totalShifts} Shifts</button>
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('ddd')+', '+moment(this.state.weekStart).format('DD')+' '+moment(this.state.weekStart).format('MMM')} - {moment(this.state.weekEnd).format('ddd')+', '+moment(this.state.weekEnd).format('DD')+' '+moment(this.state.weekEnd).format('MMM')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>
            <button
                type="button"
                className="btn btn-sm btn-success mr-2"
                onClick={this.handleApproveTimesheets123}
              >
                Approve ({this.state.totalShifts.totalShifts-this.state.totalShifts.totalApproved})
            </button> 
            <button
              type="button"
              className="btn btn-secondary mr-2 btn-sm btn-box-shadow"
              onClick={() => {
                this.showCopyShiftsModal();
                this.calculatePasteWeek();
              }}
            >
              Copy week
          </button>
          {
            this.state.totalShifts.totalShifts>0?
              <div className="btn-group mr-2">
                <a href={`${GlobalFunctions.extractToken().API_URL}/shifts/exportExcel?start=${moment(this.state.weekStart).format('MM-DD-YYYY')}&end=${moment(this.state.weekEnd).format('MM-DD-YYYY')}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
                  type="button"
                  className="btn btn-sm btn-primary btn-box-shadow"
                >
                  &#8645; Export 
                  </a>
              </div>
              :
              ''
          }
            
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
              }}
            >
              &#8635; Refresh
          </button>
          </div>
        </div>
        <div

        >
          {/* <BigdataTable data={this.state.DatabaseData} columns={columns}></BigdataTable> */}
          <ReactTable
            data={this.state.DatabaseData}
            loading={this.state.loadingData}
            columns={columns}
            style={{
              height: "80vh",
              backgroundSize: "cover"
            }}
            defaultPageSize={10}
            getTdProps={(state, rowInfo, column) => {
              return {
                style: {
                  overflow: 'visible',
                }
              }
            }}
          />
        </div>

        {/* ADD SHIFTS MODAL */}
        <Modal show={this.state.ShowAddAppointmentModal} onHide={true} size="xl"  >
          <Modal.Header>
            <Modal.Title>
              <h5 className="modal-title d-flex justify-content-start" >{this.state.selectedStaffName}
                <span className=" d-flex justify-content-end modal-title" >[{moment(this.state.selectedShiftDate).format('DD-MM-YYYY')}]</span>
                {
                  this.state.isStaffAvailable?
                  <span className="modal-title badge bg-success d-flex justify-content-end" >Available for work today</span>
                  :
                  <span className="modal-title badge bg-danger">Not Available for work today</span>
                }
                
              </h5>
            </Modal.Title>
            <button type="button" className="close" onClick={this.hideAddAppointmentModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div class="row">
            <ul class="nav nav-tabs col-md-9" id="myTab" role="tablist">
           
              <li class="nav-item">
                <a 
                onClick={()=>{
                  this.setState({clientShiftOptionsShow:false})
                }}
                class={this.state.staffAppointments.concat(this.state.locationShifts).length==0?"nav-link active":"nav-link"} id="profile-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Add shifts</a>
              </li>
              <li class="nav-item">
                <a 
                onClick={()=>{
                  this.setState({clientShiftOptionsShow:true})
                }}
                class={this.state.staffAppointments.concat(this.state.locationShifts).length!=0&&this.state.clientShiftOptionsShow?"nav-link active":"nav-link"} id="home-tab" data-toggle="tab" href="#shift" role="tab" aria-controls="shift" aria-selected="false">Shifts</a>
              </li>
           
            </ul>
            {
              this.state.staffAppointments.concat(this.state.locationShifts).length!=0?
              this.state.clientShiftOptionsShow?
              <div className="col-md-3" >
                <button
                    type="button"
                    class="btn btn-outline-success btn-sm mr-1"
                    onClick={() => {
                      //Englon00
                      this.handleApproveTimeSheetsAlert();
                    }}
                  >
                    <small >Approve</small>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mr-1"
                    onClick={() => {
                      this.cancelAppointmentAlert();
                    }}
                  >
                    <small>Cancel</small>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-warning btn-sm mr-1"
                    onClick={() => {
                      this.confirmAppointmentAlert_01();
                    }}
                  >
                    <small>Confirm</small>
                </button>
                <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        this.deleteAppointmentAlert(this.state.selectedListClientAppointmentIds);
                      }}
                    >
                      <small>Delete</small>
                </button>
              </div>
              :
              ''
              :
              ''
            }
            </div>
            <div class="tab-content" id="myTabContent">
           
              <div class={this.state.staffAppointments.concat(this.state.locationShifts).length==0||!this.state.clientShiftOptionsShow?"tab-pane fade show active" : "tab-pane fade"} id="home" role="tabpanel" aria-labelledby="home-tab">
              <div>
                {
                  this.state.hideLocationShiftInputFields?
                    <form id="addClientShiftModal" onSubmit={(event)=>{
                      this.handleAddAppointment(event, this.state.selectedRecuringDays);
                      }}>
                      <div 
                        id = 'seee'
                        name ='ssss'
                        onClick={(event)=>{
                          this.setState({hideLocationShiftInputFields : false})
                        }}
                        class="form-check">
                        <input checked={this.state.hideLocationShiftInputFields?``:`checked`} class="form-check-input" type="checkbox" value="" name="hideLocationShiftInputFields"/>
                        <label class="form-check-label" for="flexCheckChecked" id='oi123' name='io00'>
                          SIL/Admin Shift
                        </label>
                      </div>
                      <div className="row">
                      <div className="col-md-12 form-row">
                            <div className="form-group col-md-5">
                              <label htmlFor="inputEmail4">Who is working?</label>
                              <input required className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                            </div>
                            <div className="form-group col-md-7">
                              <label className="row ml-4" htmlFor="inputEmail4">
                                Repeating for &nbsp;
                                <div class="form-check  ml-4"
                                  onClick={(event)=>{
                                    this.setState({weekOrFortnightSelected: false, selectedReatPeriod: 'ThisWeek'})
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='ThisWeek'?true:false} name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat"  class="form-check-label" for="flexCheckChecked">
                                    This week Only
                                  </label>
                                </div>
                                <div class="form-check  ml-4"
                                  onClick = {(event)=>{
                                    var dates=[]
                                    var pos=0;
                                    while(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY')<=moment(this.state.weekEnd).format('MM/DD/YYYY')){
                                      dates.push(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY'));
                                      pos++;
                                    }
                                    this.setState({ upcomingWeekDays: dates, weekOrFortnightSelected: true, selectedReatPeriod: 'NextWeek'});
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='NextWeek'?true:false}  name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat"  class="form-check-label" for="flexCheckChecked">
                                    Next Week Only
                                  </label>
                                </div>
                                <div class="form-check  ml-4"
                                  onClick = {(event)=>{
                                    var dates=[]
                                    var pos=0;
                                    while(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY')<=moment(this.state.weekEnd).format('MM/DD/YYYY')){
                                      dates.push(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY'));
                                      pos++;
                                    }
                                    this.setState({ upcomingWeekDays: dates, weekOrFortnightSelected: true, selectedReatPeriod: 'NextFortnight'});
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='NextFortnight'?true:false}  name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat" class="form-check-label" for="flexCheckChecked">
                                    Next Fortnight Only
                                  </label>
                                </div>
                                </label>
                                {
                                  this.state.weekOrFortnightSelected?
                                  <div class="row ">
                                  {this.state.upcomingWeekDays.map(element=>
                                      <div 
                                          className="ml-3"
                                          onClick={() => {
                                            var arr = [];
                                            arr = this.state.selectedRecuringDays;
                                            const period=this.state.selectedReatPeriod;
                                            const nextweekDay=moment(element).add(1,'week').format('MM/DD/YYYY');
                                            const nextFortnightDay=moment(element).add(2,'week').format('MM/DD/YYYY');
                                            const index = period=='NextFortnight'? arr.findIndex(x => x == nextFortnightDay):arr.findIndex(x => x == nextweekDay);
                                            
                                            if (index >= 0) {
                                              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));                                             
                                            } else {
                                              if(period=='NextFortnight'){
                                                arr.push(nextFortnightDay)
                                              }else{
                                                arr.push(nextweekDay)
                                              }
                                            }
                                            this.setState({ selectedRecuringDays: arr });
                                          }}
                                          ><a href="#"><input  checked={(this.state.selectedRecuringDays.includes(moment(element).add(1,'week').format('MM/DD/YYYY'))&&this.state.selectedReatPeriod=='NextWeek')||(this.state.selectedRecuringDays.includes(moment(element).add(2,'week').format('MM/DD/YYYY'))&&this.state.selectedReatPeriod=='NextFortnight')} type="checkbox" id='io4' name="1"></input>{moment(element).format('dddd')}</a>
                                              </div> 
                                        )}
                                    </div>
                                    :
                                    <div class="row ">
                                      {this.state.remainingWeekDays.map(element=>
                                          <div 
                                          className="ml-3"
                                          onClick={() => {
                                            var arr = [];
                                            arr = this.state.selectedRecuringDays;
                                            var index = arr.findIndex(x => x == element);
                                            if (index >= 0) {
                                              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                            } else {
                                              arr.push(element)
                                            }
                                            this.setState({ selectedRecuringDays: arr });
                                          }}
                                          ><a href="#"><input  checked={this.state.selectedRecuringDays.includes(element)} type="checkbox" id='io4' name="1"></input>{moment(element).format('dddd')}</a>
                                              </div> 
                                        )}
                                    </div>
                                }

                            </div>
                          </div>

                        <div className="col-md-12">
                          <div className="form-row mt-2">
                            <div className="form-group col-md-6">
                              <label htmlFor="inputEmail4">Participants</label>
                              <Select
                                value={this.state.clientSelectedValue}
                                onChange={(event) => {
                                    var clientId = event.value
                                    var clientLocation = "";
                                    var trackingItemID = null;
                                    this.setState({ clientId: clientId, clientSelectedValue : event })
                                    this.getParticipantServices(clientId);
                                    this.state.clients.forEach((element) => {
                                      if (element.id == clientId) {
                                        clientLocation = element.chaddress;
                                        trackingItemID = element.trackingItemID;
                                      }
                                    })
                                    this.setState({ clientLocation: clientLocation, trackingItemID:trackingItemID , serviceSelectList : [], trackingItemID : trackingItemID})
                                  }
                                }
                                options={this.state.clientSelectList}
                              /> 
                              
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="inputEmail4">Client services</label>
                              <Select
                                value={this.state.serviceSelectedValue}
                                onChange={(event) => {
                                  var serviceId = event.value;
                                  var arr = []
                                  arr = this.state.supportServicesPerClient;
                                  var index = arr.findIndex(x => x.supportServiceId == serviceId);
                                  if (index >= 0) {
                                    this.setState({ costPerHour: arr[index].servicePrice, serviceSelectedValue : event, clientServiceId : serviceId})
                                  }
                                }
                                }
                                options={this.state.serviceSelectList}
                              /> 
                              
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputAddress">Home Address</label>
                              <input value={this.state.clientLocation} required type="text" className="form-control" name="clientLocation" onChange={event => this.setTitle(event.target)} />
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label htmlFor="inputCity">Start time</label>
                              <input required value={this.state.startTime} type="time" className="form-control" onChange={(event) => {
                                this.setTitle(event.target);
                                this.calculateDuration123({ startTime: event.target.value, endTime: this.state.startTime });
                                }} name="startTime" />

                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="inputCity">End time</label>
                              <input
                                required
                                type="time"
                                className="form-control"
                                onChange={(event) => {
                                  this.setTitle(event.target)
                                  this.calculateDuration123({ startTime: this.state.startTime, endTime: event.target.value });
                                }}
                                value={this.state.endTime}
                                name="endTime"/>
                            </div>
                            
                            <div className="form-group col-md-4">
                              <label htmlFor="inputCity">Service cost(${parseFloat(this.state.costPerHour).toFixed(2)}/hour)</label>
                              <input disabled Value={parseFloat(parseFloat(this.state.costPerHour) * this.state.calculatedDuration).toFixed(2)} type="text" className="form-control" onChange={event => this.setTitle(event.target)} name="costPerHour" />
                            </div>
                           
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Notes</label>
                            <textarea className="form-control" onChange={event => this.setTitle(event.target)} value={this.state.notes} name="notes" rows="3"></textarea>

                          </div>
                        </div>
                      </div>
                      {
                        this.state.savingData ?
                          <button
                            className="btn btn-warning"
                            type="Submit"
                            disabled >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          Saving data...
                      </button>
                          :
                          <button
                            className="btn btn-warning"
                            type="Submit">
                            Save appointment
                      </button>
                      }

                    </form>
                    :
                    <form onSubmit={event=>this.handleSubmit(event, this.state.selectedRecuringDays)}>
                      <div 
                        id ='ee1'
                        onClick={(event)=>{
                          this.setState({hideLocationShiftInputFields : true})
                        }}
                        class="form-check">
                        <input id ='ee111' name= 'were' checked={!this.state.hideLocationShiftInputFields?``:`checked`} class="form-check-input" type="checkbox" value="" name="hideLocationShiftInputFields"/>
                        <label class="form-check-label" name='www' id='qee' for="flexCheckChecked">
                          A participant service
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <div className="form-group col-md-5">
                              <label htmlFor="inputEmail4">Who is working?</label>
                              <input required className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                            </div>
                            <div className="form-group col-md-7">
                            <label className="row ml-4" htmlFor="inputEmail4">
                                Repeating for &nbsp;
                                <div class="form-check  ml-4"
                                  onClick={(event)=>{
                                    this.setState({weekOrFortnightSelected: false, selectedReatPeriod: 'ThisWeek'})
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='ThisWeek'?true:false} name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat"  class="form-check-label" for="flexCheckChecked">
                                    This week Only
                                  </label>
                                </div>
                                <div class="form-check  ml-4"
                                  onClick = {(event)=>{
                                    var dates=[]
                                    var pos=0;
                                    while(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY')<=moment(this.state.weekEnd).format('MM/DD/YYYY')){
                                      dates.push(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY'));
                                      pos++;
                                    }
                                    this.setState({ upcomingWeekDays: dates, weekOrFortnightSelected: true, selectedReatPeriod: 'NextWeek'});
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='NextWeek'?true:false}  name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat"  class="form-check-label" for="flexCheckChecked">
                                    Next Week Only
                                  </label>
                                </div>
                                <div class="form-check  ml-4"
                                  onClick = {(event)=>{
                                    var dates=[]
                                    var pos=0;
                                    while(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY')<=moment(this.state.weekEnd).format('MM/DD/YYYY')){
                                      dates.push(moment(this.state.weekStart).add(pos,'days').format('MM/DD/YYYY'));
                                      pos++;
                                    }
                                    this.setState({ upcomingWeekDays: dates, weekOrFortnightSelected: true, selectedReatPeriod: 'NextFortnight'});
                                  }}
                                >
                                  <input checked={this.state.selectedReatPeriod=='NextFortnight'?true:false}  name="repeat" class="form-check-input" type="radio" value="" id="flexCheckChecked" />
                                  <label name="repeat" id="repeat" class="form-check-label" for="flexCheckChecked">
                                    Next Fortnight Only
                                  </label>
                                </div>
                                </label>
                                {
                                  this.state.weekOrFortnightSelected?
                                  <div class="row ">
                                  {this.state.upcomingWeekDays.map(element=>
                                      <div 
                                          className="ml-3"
                                          onClick={() => {
                                            var arr = [];
                                            arr = this.state.selectedRecuringDays;
                                            const period=this.state.selectedReatPeriod;
                                            const nextweekDay=moment(element).add(1,'week').format('MM/DD/YYYY');
                                            const nextFortnightDay=moment(element).add(2,'week').format('MM/DD/YYYY');
                                            const index = period=='NextFortnight'? arr.findIndex(x => x == nextFortnightDay):arr.findIndex(x => x == nextweekDay);
                                            
                                            if (index >= 0) {
                                              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));                                             
                                            } else {
                                              if(period=='NextFortnight'){
                                                arr.push(nextFortnightDay)
                                              }else{
                                                arr.push(nextweekDay)
                                              }
                                            }
                                            this.setState({ selectedRecuringDays: arr });
                                          }}
                                          ><a href="#"><input  checked={(this.state.selectedRecuringDays.includes(moment(element).add(1,'week').format('MM/DD/YYYY'))&&this.state.selectedReatPeriod=='NextWeek')||(this.state.selectedRecuringDays.includes(moment(element).add(2,'week').format('MM/DD/YYYY'))&&this.state.selectedReatPeriod=='NextFortnight')} type="checkbox" id='io4' name="1"></input>{moment(element).format('dddd')}</a>
                                              </div> 
                                        )}
                                    </div>
                                    :
                                    <div class="row ">
                                      {this.state.remainingWeekDays.map(element=>
                                          <div 
                                          className="ml-3"
                                          onClick={() => {
                                            var arr = [];
                                            arr = this.state.selectedRecuringDays;
                                            var index = arr.findIndex(x => x == element);
                                            if (index >= 0) {
                                              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                            } else {
                                              arr.push(element)
                                            }
                                            this.setState({ selectedRecuringDays: arr });
                                          }}
                                          ><a href="#"><input  checked={this.state.selectedRecuringDays.includes(element)} type="checkbox" id='io4' name="1"></input>{moment(element).format('dddd')}</a>
                                              </div> 
                                        )}
                                    </div>
                                }
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputAddress">Location</label>
                              <select 
                                className="form-control"
                                name="address"
                                value={this.state.address}
                                onChange={(event) => {
                                  const index=this.state.companyLocations.findIndex(x=>x.id==event.target.value);
                                  this.setState({trackingItemID: this.state.companyLocations[index].trackingItemID})
                                  this.setTitle(event.target)}
                                }
                              >
                                <option>---Select Location---</option>
                                {
                                  this.state.companyLocations.map((element) =>
                                    <option selected={this.state.selectedFilterItermId==element.id?'selected':''} value={element.id}>{element.name} ({element.address})</option>
                                  )
                                }
                              </select>
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label htmlFor="inputCity">Start time</label>
                              <input required value={this.state.startTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="inputCity">End time</label>
                                <input required type="time" 
                                className="form-control" 
                                value={this.state.endTime}
                                onChange={(event) => {
                                  this.setTitle(event.target);
                                  this.calculateDuration123({ startTime: this.state.startTime, endTime: event.target.value });
                                }} 
                                name="endTime" />
                              </div>
                             
                              <div className="form-group col-md-4">
                              <label htmlFor="inputCity">Breaking Minutes(less than {this.state.calculatedDuration*60} minutes)</label>
                                <input  type="number" className="form-control" onChange={event => this.setTitle(event.target)} value={this.state.breakMin} name="breakMin" />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlTextarea1">Notes</label>
                              <textarea
                                className="form-control"
                                onChange={event => this.setTitle(event.target)} value={this.state.notes} name="notes" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      <div>
                        {
                          this.state.savingData ?
                            <button
                              className="btn btn-info"
                              type="button">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                                Saving data...
                        </button>
                            :
                            <button
                              className="btn btn-info"
                              type="submit"
                              onClick={() => {
                                this.setState({ isSubmitingInProgress: true })
                                // this.handleEditShift();
                              }}
                              >
                              Save Changes
                        </button>
                        }

                      </div>
                  </form>
                  
                }
                </div>
              </div>
              
             <div class={this.state.staffAppointments.concat(this.state.locationShifts).length!=0&&this.state.clientShiftOptionsShow?"tab-pane fade show active" : "tab-pane fade"} id="shift" role="tabpanel" aria-labelledby="contact-tab">
             <ReactTable
                  data={this.state.staffAppointments.concat(this.state.locationShifts)}
                  loading={this.state.loadingData}
                  columns={tableAppointments}
                  defaultPageSize={10}
                />
             </div>

            </div>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>

        </Modal>

        {/* EDIT MODAL */}
        <Modal show={this.state.locationShiftEditModal} onHide={true} size="xl"  >
          <Modal.Header>
            <Modal.Title>
              <h5 className="modal-title d-flex center" >{this.state.selectedStaffName}
                <span className=" d-flex justify-content-end modal-title" >[{moment(this.state.selectedShiftDate).format('DD-MM-YYYY')}]</span>
              </h5>
            </Modal.Title>
            <button type="button" className="close" onClick={()=>this.setState(
              {
                locationShiftEditModal : false, 
                ShowAddAppointmentModal : true,
                clientServiceId : 0,
                serviceSelectedValue : {label : '', value : 0},
                clientLocation : '', 
                costPerHour : 0,
                 clientSelectedValue : {label : '', value : 0}, 
                 locationId: 0, 
                 breakMin : 0, 
                 shiftId: 0, 
                 isActiveSleepOver : null, 
                 startTime : '', 
                 endTime : '', 
                 notes : '', 
                 staffId: 0, 
                 clientId: 0, 
                 addExtraHourForInvoicing : false
              }
              )}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
          {
                  isNaN(this.state.shiftId)?
                    <form id="addClientShiftModal">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-row mt-2">
                            <div className="form-group col-md-6">
                              <label htmlFor="inputEmail4">Participants</label>
                              <Select
                                value={this.state.clientSelectedValue}
                                onChange={(event) => {
                                    var clientId = event.value
                                    var clientLocation = "", trackingItemID="";
                                    this.setState({ clientId: clientId, clientSelectedValue : event })
                                    this.getParticipantServices(clientId);
                                    this.state.clients.forEach((element) => {
                                      if (element.id == clientId) {
                                        clientLocation = element.chaddress
                                        trackingItemID =element.trackingItemID
                                      }
                                    })
                                    this.setState({ clientLocation: clientLocation, trackingItemID : trackingItemID , serviceSelectList : []})
                                  }
                                }
                                options={this.state.clientSelectList}
                              /> 
                              
                            </div>
                            <div 
                            
                            className="form-group col-md-6">
                              <label htmlFor="inputEmail4">Client services</label>
                              <Select
                                value={this.state.serviceSelectedValue}
                                onChange={(event) => {
                                  var serviceId = event.value;
                                  var arr = []
                                  arr = this.state.supportServicesPerClient;
                                  var index = arr.findIndex(x => x.supportServiceId == serviceId);
                                  if (index >= 0) {
                                    this.setState({ costPerHour: arr[index].servicePrice, serviceSelectedValue : event, clientServiceId : serviceId})
                                  }
                                }
                                }
                                options={this.state.serviceSelectList}
                              /> 
                              
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputAddress">Home Address</label>
                              <input value={this.state.clientLocation} required type="text" className="form-control" name="clientLocation" onChange={event => this.setTitle(event.target)} />
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label htmlFor="inputCity">Start time</label>
                              <input required value={this.state.startTime} type="time" className="form-control" onChange={(event) => {
                                this.setTitle(event.target);
                                this.calculateDuration123({ startTime: event.target.value, endTime: this.state.startTime });
                                }} name="startTime" />

                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputCity">End time</label>
                              <input
                                required
                                type="time"
                                className="form-control"
                                onChange={(event) => {
                                  this.setTitle(event.target)
                                  this.calculateDuration123({ startTime: this.state.startTime, endTime: event.target.value });
                                }}
                                value={this.state.endTime}
                                name="endTime" />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputCity"> Add Extra Hour For Invoicing</label>
                              <div className="row ml-1">
                                <div
                                  onClick={(event)=>{
                                    this.setState({addExtraHourForInvoicing : true});
                                  }}
                                ><a href="#"><input required checked={this.state.addExtraHourForInvoicing?true:false} type="radio" name="1"></input> Yes</a></div> &nbsp;  &nbsp; 
                                <div  
                                  onClick={(event)=>{
                                    this.setState({addExtraHourForInvoicing : false});
                                  }}
                                ><a href="#"><input  checked={this.state.addExtraHourForInvoicing?false:true}  type="radio" name ="1"></input> No</a>
                                </div>
                              </div>
                            </div>
                           
                            <div className="form-group col-md-3">
                              <label htmlFor="inputCity">Service cost(${parseFloat(this.state.costPerHour).toFixed(2)}/hour)</label>
                              <input disabled Value={parseFloat(parseFloat(this.state.costPerHour) * this.state.calculatedDuration).toFixed(2)} type="text" className="form-control" onChange={event => this.setTitle(event.target)} name="costPerHour" />
                            </div>
                           
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Notes</label>
                            <textarea className="form-control" onChange={event => this.setTitle(event.target)} value={this.state.notes} name="notes" rows="3"></textarea>

                          </div>
                        </div>
                      </div>
                      {
                        this.state.savingData ?
                          <button
                            className="btn btn-warning"
                            type="Submit"
                            disabled >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          Saving data...
                      </button>
                          :
                          <button
                            className="btn btn-warning"
                            onClick={this.handleSubmitEditedAppointment}
                            type="button">
                            Save appointment
                      </button>
                      }

                    </form>
                    :
                    <form onSubmit={this.handleEditShift}>
                     
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputEmail4">Who is working?</label>
                              <input required className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputAddress">Location</label>
                              <select 
                                className="form-control"
                                name="locationId"
                                value={this.state.locationId}
                                onChange={(event) => {
                                  const index = this.state.companyLocations.findIndex(x=>x.id==event.target.value)
                                  this.setState({trackingItemID : this.state.companyLocations[index].trackingItemID})
                                  this.setTitle(event.target);
                                  console.log(this.state.companyLocations[index].trackingItemID)
                                }}
                              >
                                <option>---Select Location---</option>
                                {
                                  this.state.companyLocations.map((element) =>
                                    <option selected={this.state.locationId==element.id?'selected':''} value={element.id}>{element.name} ({element.address})</option>
                                  )
                                }
                              </select>
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label htmlFor="inputCity">Start time</label>
                              <input required value={this.state.startTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="inputCity">End time</label>
                                <input required type="time" 
                                className="form-control" 
                                value={this.state.endTime}
                                onChange={(event) => {
                                  this.setTitle(event.target);
                                  this.calculateDuration123({ startTime: this.state.startTime, endTime: event.target.value });
                                }} 
                                name="endTime" />
                              </div>
                              <div className="form-group col-md-2">
                              <label htmlFor="inputCity"> Sleepover {this.state.isActiveSleepOver} </label>
                              <div className="row ml-1">
                                <div
                                  onClick={(event)=>{
                                    if(this.state.isActiveSleepOver==true||this.state.isActiveSleepOver ==null){
                                      this.setState({isActiveSleepOver : false})
                                    }
                                    if(this.state.isActiveSleepOver ==false){
                                      this.setState({isActiveSleepOver : null})
                                    }
                                  }}
                                ><a href="#"><input  checked={this.state.isActiveSleepOver==false?true:false} type="checkbox" id='io4' name="1"></input> Sleepover</a></div> &nbsp;  &nbsp; 
                                <div  
                                  onClick={(event)=>{
                                    if(this.state.isActiveSleepOver==true||this.state.isActiveSleepOver ==null){
                                      this.setState({isActiveSleepOver : true})
                                    }
                                    if(this.state.isActiveSleepOver ==true){
                                      this.setState({isActiveSleepOver : null})
                                    }
                                  }}
                                ><a href="#"><input  checked={this.state.isActiveSleepOver==true?true:false}  type="checkbox" id='5o' name ="12"></input> Awake</a>
                                </div>
                                
                              </div>
                            </div>
                              <div className="form-group col-md-4">
                              <label htmlFor="inputCity">Breaking Minutes(less than {this.state.calculatedDuration*60} minutes)</label>
                                <input  type="number" className="form-control" onChange={event => this.setTitle(event.target)} value={this.state.breakMin} name="breakMin" />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlTextarea1">Notes</label>
                              <textarea
                                className="form-control"
                                onChange={event => this.setTitle(event.target)} value={this.state.notes} name="notes" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      <div>
                        {
                          this.state.savingData ?
                            <button
                              className="btn btn-info"
                              type="Submit">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                                Saving data...
                        </button>
                            :
                            <button
                              className="btn btn-info"
                              type="Submit">
                              Save Changes
                        </button>
                        }

                      </div>
                  </form>
                  
                }
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>

        </Modal>
        {/* COPY SHIFTS MODAL */}
        <Modal show={this.state.ShowCopyShiftsModal} onHide={true}  >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Copy shifts</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideCopyShiftsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12" style={{ height: "250px", overflowY: "scroll" }}>
                <form>
                  <div class="form-group">
                    {
                      this.state.getNextWeeks.map((element) =>
                        <div class="form-check border rounded mb-1 p-1">
                          <input
                            onClick={() => {
                              var arr = [];
                              arr = this.state.copiedWeekShifts;
                              var index = arr.findIndex(x => x.weekStart === element.weekStart);
                              if (index >= 0) {
                                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));

                              } else {
                                arr.push({ weekStart: element.weekStart, weekEnd: element.weekEnd });
                              }
                              this.setState({ copiedWeekShifts: arr });

                              arr = [];
                            }}
                            class="form-check-input ml-1"
                            type="checkbox"
                            id="gridCheck" />
                          <label class="form-check-label ml-4" for="gridCheck">
                            {moment(element.weekStart).format("DD-MM-YYYY")} to {moment(element.weekEnd).format("DD-MM-YYYY")}
                          </label>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.handleWeekSubmit}>Paste shifts</button>
          </Modal.Footer>
        </Modal>

      </div>

    )
  }
}

export default Schedules;
