import React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import '../styles/main.css';
import ReactTable from 'react-table-6'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import 'react-table-6/react-table.css'
import { array, element } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../styles/navbar.css';
import Modal from "react-bootstrap/Modal";
import moment, { relativeTimeThreshold, duration } from 'moment'
import swal from '@sweetalert/with-react';
import date from "date-and-time";
import Spinner from 'react-bootstrap/Spinner'
import { white } from 'color-name';
import { formatDate } from 'tough-cookie';
import { Popover, OverlayTrigger } from "react-bootstrap"
import { Chart } from 'react-charts'
import GlobalFunctions from '../controllers/globalFunctions';
import Select from 'react-select';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import DatePicker from "react-datepicker";
import { BsEyeFill } from "react-icons/bs";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import "react-datepicker/dist/react-datepicker.css";
import { parseJSON } from 'date-fns';

//IMPORTING COMPONENTS;
import SweetAlertProgressBar from './components/SweetAlerts';

class Timesheets extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        DatabaseData: [],
        clients: [],
        finSupport: [],
        supportServices: [],
        linkedSupportAreas: [],
        linkedSupportService: [],
        linkedFinancialPackages: [],
        clientFin: [],
        servicePrice: 0,
        serviceCost: 0,
        isActiveSleepOver : null,
  
        clientId: 0,
        packageId: 0,
        supportAreaId: 0,
        supportServicesId: 0,
        staffId: 0,
        selectedStaffId: 0,
        selectedStaffName: "",
        selectedShiftId: 0,
        weekStart: '',
        weekEnd: '',
        selectedShiftDetails: {},
        financialPackageId: 0,
        staffList: [],
        timesheets: [],
        selectedTimesheetId: [],
        userDetails: {},
        loadingData: false,
        loadingStaff: true,
        savingData: false,
        totalTimesheetHours: 0,
        selectedFilterItermId : 0,
        shiftsList: [],
        filteredData: [],
        searchInput: "",
        companyLocations : [],
        filterSchedulesByManagerValue : {label : 'Filter By Manager', value : 0},
        filterStaffByLocationValue : {label : 'Filter By Location', value : 0},
        managersSeleectList : [
          {label : 'All Managers', value : 0}
        ],
        locationSelectList : [
          {label : 'All Locations', value : 0}
        ],
        userLocationData : [],
        userManagerData : [],
        xeroPayrollCalendar : [],
        xeroStaffSelectList : [],
        isSendingAllStaffTimeSheets : true,
        xeroPayrollCalendarId : 0,
        selectedStaffIdXero : 0,
        payCalendarStart:'',
        address : 0,
        payCalendarEnd : '',
        trackingItemID : '',
        notes : '',
        breakMin: 0
      };
  
    }
    componentDidMount () {
      GlobalFunctions.extractToken();
      this.getStaff();
      this.getClients();
      GlobalFunctions.getCompanyLocations().then(res=>{
        this.setState({companyLocations : res});
        res.forEach(element => {
          this.state.locationSelectList.push({ 
            value : element.id,
            label : element.name
          })
        });
      });
      this.calculateWeekRange();
      this.getLocationUserData();
      this.getManagerUserData();
    }

    calculateDuration = (event) =>{
      var startTime = date.parse(event.startTime, 'HH:mm:ss A', true);
      var endTime = date.parse(event.endTime, 'HH:mm:ss A', true);
      var duration = date.subtract(endTime, startTime).toMinutes();
      duration = duration / 60;
      this.setState({ calculatedDuration: duration })
    }
  
    handleChange = event => {
      this.setState({ searchInput: event.target.value }, () => {
        this.globalSearch();
      });
    };
  
    globalSearch = () => {
      let { searchInput } = this.state;
      let filteredData = this.state.shiftsList.filter(value => {
        return (
          value.staffName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      this.setState({ staffList: filteredData });
    };
  
    networkFailureAlert = (event) => {
      swal(event, " ", "warning")
    }
  
    saveAlert = ()  => {
      swal("successfully saved!", " ", "success")
        .then(() => {
          this.setState({ ShowAddModal: false });
        })
    }
    adminConfirmCompletedAlert = () =>  {
      swal("Admin successfully confirmed!", " ", "success")
        .then(() => {
          this.setState({ ShowModal: false });
        })
    }
    // approveAlert = ()  => {
    //   swal("successfully approved!", " ", "success")
    //     .then(() => {
    //       this.setState({ ShowModal: false });
    //     })
    // }
  
    deleteAlert = ()  => {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            swal("successfully deleted!", {
              icon: "success",
            });
          } else {
            swal("not deleted!");
          }
        })
    }
  
    handleInputChange = (event)  => {
      const target = event.target;
      const value = target.name === 'isGoing' ? target.checked : target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });
    }
  
    handleSubmit = (event)  => {
      this.setState({ savingData: true })
      event.preventDefault();
      const infor = {
        "staffId": Number(this.state.selectedStaffId),
        "location": this.state.address,
        "Date": this.state.date,
        "startTime": this.state.startTime,
        "endTime": this.state.endTime,
        "breaking": this.state.breakMin,
        "notes": this.state.notes,
        "allowance": 0,
        "isActiveSleepOver":this.state.isActiveSleepOver,
        "trackingItemID": this.state.trackingItemID
      };
  
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ 
            savingData: false,
            date : '',
            startTime : '',
            endTime : '',
            breakMin : 0,
            notes : ''
           })
          if (res.status == 201) {
            this.saveAlert();
            this.getTimesheets();
          }
        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
  
        })
    }
  
    handleEditShift = (event)  => {
  
      const infor = {
        "clientId": Number(this.state.clientId) == 0 ? Number(this.state.selectedShiftDetails.clientId) : Number(this.state.clientId),
        "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
        "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
        "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
        "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
        "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
        "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,
        "hourlChage": this.state.serviveCost == undefined ? this.state.selectedShiftDetails.hourlChage : this.state.serviceCost,
  
        "supportAreaId": Number(this.state.supportAreaId) == 0 ? Number(this.state.selectedShiftDetails.supportAreaId) : Number(this.state.supportAreaId),
        "supportServiceId": Number(this.state.supportServicesId) == 0 ? Number(this.state.selectedShiftDetails.supportServiceId) : Number(this.state.supportServicesId),
        "packageId": Number(this.state.financialPackageId) == 0 ? Number(this.state.selectedShiftDetails.financialPackageId) : Number(this.state.financialPackageId)
  
      };
  
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
  
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.setState({ ShowModal: false });
  
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
        });
    }
  
    handleDataInput = (event)  => {
      this.setState({ [event.name]: event.value })
    }
  
  
    getTimesheets = ()  => {
      this.setState({ loadingData: true })
      var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(timesheet => {
        var total = 0;
        timesheet.data.forEach(element => {
          total += element.duration
        })
        this.setState({ timesheets: timesheet.data, loadingData: false, totalTimesheetHours: total })
      }).catch(e => {
  
      })
    }
  
  
    calculateWeekRange = ()  => {
      let now = moment();
      const dayName = moment(now).format('dddd');
      var day = Number(now.weekday())
      var start = now.subtract(day - 1, 'days').format('MM-DD-YYYY');
      var end = now.add(6, 'days').format('MM-DD-YYYY')
      if(dayName=='Sunday'){
        end = moment(start).format('MM-DD-YYYY');
        end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
        start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
      }
      this.setState({ weekStart: start, weekEnd: end })
    }
  
    xeroSyncTimesheets = (event)  => {
      event.preventDefault();
      if (this.state.selectedStaffId!=0&&!this.state.isSendingAllStaffTimeSheets&&this.state.selectedTimesheetId.length!=0) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/timeSheetToSendToXero?fortnightStart=${moment(this.state.payCalendarStart).format('MM-DD-YYYY')}&fortnightEnd=${moment(this.state.payCalendarEnd).format('MM-DD-YYYY')}&xeroPayrollCalendarId=${this.state.xeroPayrollCalendarId}&staffId=${this.state.selectedStaffId}&timeSheetIds=${this.state.selectedTimesheetId}&all=${this.state.isSendingAllStaffTimeSheets}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.post(url1,this.state.selectedTimesheetId).then(client => {
            this.setState({selectedTimesheetId:[]})
            this.getTimesheets();
            swal("successfully synced", " ", "success")
          }).catch(error => {
            swal(error.response.data, " ", "warning")
          })
      }else if(!this.state.isSendingAllStaffTimeSheets&&(this.state.selectedStaffId==0||this.state.selectedTimesheetId.length==0)){
        swal("Please select a staff member", " ", "warning");
        this.state.selectedStaffId==0?swal("Please select a staff member", " ", "warning"):swal("Select Timesheets to send", " ", "warning")
      }
      if(this.state.isSendingAllStaffTimeSheets){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/timeSheetToSendToXero?fortnightStart=${moment(this.state.payCalendarStart).format('MM-DD-YYYY')}&fortnightEnd=${moment(this.state.payCalendarEnd).format('MM-DD-YYYY')}&xeroPayrollCalendarId=${this.state.xeroPayrollCalendarId}&staffId=${this.state.selectedStaffIdXero}&all=${this.state.isSendingAllStaffTimeSheets}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.post(url1).then(client => {
            this.getTimesheets();
            this.setState({selectedTimesheetId:[]})
            swal("successfully synced", " ", "success")
          }).catch(error => {
            swal(error.response.data, " ", "warning")
          })
      }
    }
    getClients = ()  => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ clients: client.data });
      })
  
    }
  
    getFinSupport = ()  => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/finsupport?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ finSupport: client.data });
      })
  
    }
  
    getSupportServices = ()  => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/support-services?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ supportServices: client.data });
      })
  
    }
 
    handleApproveTimeSheetsAlert =()=>{
      swal({
        title: "The selected shifts will be approved",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
       if(willDelete){
        this.handleApproveTimesheets(this.state.selectedTimesheetId);
       }
      })
    }
  
    handleApproveTimesheets = (ids) =>  {
      const locationShiftsIDs = ids.filter(function (x) {return !(isNaN(x.shiftId));});
      const clientShiftsIDs = ids.filter(function (x) {return isNaN(x.shiftId);});
      var url1= `${GlobalFunctions.extractToken().API_URL}/shifts/approveAppointment?ids=${clientShiftsIDs}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`;
      var url2 = `${GlobalFunctions.extractToken().API_URL}/shifts/approve?ids=${locationShiftsIDs}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`;
      axios.post(url1, clientShiftsIDs).then(client1 => {
        axios.post(url2, locationShiftsIDs).then(client => {
          this.approvedShiftAlert(Number(clientShiftsIDs.length+locationShiftsIDs.length), Number(client.data.successCount+client1.data.successCount));
          this.setState({ selectedTimesheetId: [] });
        })
        
      }).catch(error=>{
        swal("Failed to approve the shift(s)", " ", "warning");
        console.log(error)
      })
    }
    approvedShiftAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is Successfully approved`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.getTimesheets();
        })
    }
 
    deleteTimesheetAlert=(event)=>{
      swal({
        title: "Selected Items will be Deleted",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
         if(willDelete){
          var BreakException = {};
          try {
            var pos=0;
            this.state.selectedTimesheetId.forEach(data=>{
              const completeStatus = data.isCompleted;
              if(completeStatus) throw BreakException;
              pos++;
              if(pos==this.state.selectedTimesheetId.length){
                this.handleDeleteTimesheet(this.state.selectedTimesheetId);
              }
            })
          } catch (e) {
              swal({
                title: "You have selected completed Shift",
                text: "Those shifts will won't be confirmed",
                buttons: true,
                dangerMode: true,
              }).then((willDelete1) => {
               if(willDelete1){
                this.handleDeleteTimesheet(this.state.selectedTimesheetId);
               }
              })
          }
          
         }
        })
    }
  
    handleDeleteTimesheet = (ids) =>  {
      this.setState({ loadingData: true })
      var pos=0;
      var success=0;
      ids.forEach(element => {
        var url1 = "";
        if (isNaN(element.shiftId)) {
          url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteAppointment?appointmentId=${element.shiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        } else {
          url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${element.shiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        }
  
        axios.get(url1).then(client => {
          if (client.status == 200) {
            pos++; 
            success++;
            this.setState({selectedTimesheetId:[]})
            this.deletedShiftAlert(ids.length, success);
          }
  
        }).catch(error => {
          console.log(error)
          pos++;
          this.deletedShiftAlert(ids.length, success);
        })
  
      });
      this.setState({ loadingData: false })
    }
  
    deletedShiftAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is deleted successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.getTimesheets();
        })
    }
  
    confirmTimesheetAlert=(event)=>{
      var BreakException = {};
      try {
        var pos=0;
        this.state.selectedTimesheetId.forEach(data=>{
          if(!data.isCompleted) throw BreakException;
          pos++;
          if(pos==this.state.selectedTimesheetId.length){
            this.handleConfirmCompletedTimesheets(this.state.selectedTimesheetId);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Incompleted Shift",
            text: "Those shifts will won't be confirmed",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleConfirmCompletedTimesheets(this.state.selectedTimesheetId);
           }
          })
      }
    }
  
    handleConfirmCompletedTimesheets = (ids)  => {
      var pos=0;
      var success=0;
      this.state.selectedTimesheetId.forEach(data => {
        var id = data.shiftId
        if (isNaN(id)) {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirmAppointment?appointmentId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
              this.setState({selectedTimesheetId:[]})
                this.confirmedShiftAlert(ids.length, success);
            }
          }).catch(error => {
            pos++;
            this.confirmedShiftAlert(ids.length, success);
          })
        } else {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/adminConfirm?shiftId=${id}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
              this.confirmedShiftAlert(ids.length, success);
            } else {
  
            }
          }).catch(error => {
            pos++;
            this.confirmedShiftAlert(ids.length, success);
          })
        }
  
      })
    }
    confirmedShiftAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is successfully confirmed`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.getTimesheets();
        })
    }
    getNextWeek = ()  => {
      var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
      start = moment(start).add(1, 'days').format('MM-DD-YYYY');
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
  
      var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(timesheet => {
        this.setState({ timesheets: timesheet.data })
  
      }).catch(e => {
  
      })
      this.setState({ weekStart: start, weekEnd: end })
  
    }
  
    getPreviousWeek = ()  => {
      var end = moment(this.state.weekStart).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');
  
      var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(timesheet => {
        this.setState({ timesheets: timesheet.data })
  
      }).catch(e => {
  
      })
  
      this.setState({ weekStart: start, weekEnd: end })
  
    }
  
    getStaff = ()  => {
      this.setState({ loadingStaff: true });
      //FETCHING ALL STAFF FROM API
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        var arr = [];
        staff.data.forEach(element => {
          this.state.xeroStaffSelectList.push({
            label : `${element.lastname} ${element.firstname}`,
            value : element.id
          })
          arr.push({ staffName: `${element.lastname} ${element.firstname}`, id: element.id })
        });
        //SELECTING MANAGERS FOR FILTERING PURPOSE
        this.setState({managersSeleectList :  []});
        this.state.managersSeleectList.push({label : 'All Managers', value : 0})
        staff.data.forEach(element=>{
          if (element.access_level==3) {
            this.state.managersSeleectList.push({
              label : element.lastname+' '+element.firstname, 
              value : element.id
            })
          }
        })
        this.setState({ staffList: arr, loadingStaff: false, shiftsList: arr });
      }).catch(error => {
  
      })
    }
  
    getLocationUserData = () =>{
      var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userProperty?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(res=>{
        this.setState({userLocationData : res.data});
      })
    }
  
    getManagerUserData = () =>{
      var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userManager?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url).then(res=>{
        this.setState({userManagerData : res.data});
      })
    }
  
    getXeroPayrollCalendar = ()   => {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/xeroPayCalendar?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ xeroPayrollCalendar : client.data });
      }).catch(error=>{
        console.log()
      })
  
    }
  
    sychronizeStaffDataWithXero = () =>{
      var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/combineEmployees/?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`;
      axios.post(url1).then(client => {
        swal("successfully synchronised", " ", "success")
      }).catch(error =>{
        console.log(error)
        swal("Error occured", " ", "warning")
      })
    }
  
  
    completedAppointmentAlert_01=(event)=>{
      var BreakException = {};
      let now = new Date();
      now = moment(now).format('MM/DD/YYYY');
      try {
        var pos =0;
        this.state.selectedTimesheetId.forEach(data=>{
          if(!data.isApproved || moment(data.date).format('MM/DD/YYYY')>now) throw BreakException;
          pos++;
          if(pos==this.state.selectedTimesheetId.length){
            this.handleCompleted(this.state.selectedTimesheetId);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Unapproved shifts or Upcoming shifts",
            text: "They will NOT be changed status to Completed",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleCompleted(this.state.selectedTimesheetId);
           }
          })
      }
  }
  
    handleCompleted = (ids)  =>  {
      let now = new Date();
      now = moment(now).format('MM/DD/YYYY');
      var pos=0;
      var success=0;
      ids.forEach(data => {
        var id = data.shiftId;
        if (isNaN(id)) {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completeAppointment?date=${now}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${id}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
                this.completedAlert(ids.length, success);
            }
          }).catch(error => {
            pos++;
              this.completedAlert(ids.length, success);
          })
        } else {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completed?date=${now}&shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            if (client.status == 200) {
              pos++; 
              success++;
                this.completedAlert(ids.length, success);
              
            }
          }).catch(error => {
            pos++;
              this.completedAlert(ids.length, success);
            //this.setState({progress : i/this.state.selectedTimesheetId.length*100});
          })
        }
      }
      )
  
    }
  
    completedAlert = (total, success)  => {
      var percentageSuccess=(success/total)*100;
      percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
      swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is successfully completed`} percent={percentageSuccess}></SweetAlertProgressBar>)
        .then(() => {
          this.setState({ selectedTimesheetId: [] })
          this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
          this.setState({ ShowModal: false });
        })
    }
  
    render() {
      const columns = [{
        Header: () => (
          <button
            onClick={() => {
              var arr = [];
              arr = this.state.selectedTimesheetId;
              if (arr.length == 0) {
                this.state.timesheets.forEach(element => {
                  arr.push(element)
                });
                this.setState({ selectedTimesheetId: arr });
              } else {
                this.setState({ selectedTimesheetId: [] });
              }
            }}
            className="btn text-white  btn-warning btn-block"
            type="button">
            &#10003;
          </button>
        ),
        maxWidth: 46,
        Cell: row => {
  
          return (
            <div
            >
              <input
                onClick={() => {
                  var arr = [];
                  arr = this.state.selectedTimesheetId;
                  var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                  if (index >= 0) {
                    arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                  } else {
                    arr.push(row.original)
                  }
                  this.setState({ selectedTimesheetId: arr });
                }
                }
                type="checkbox" className="ml-2" checked={this.state.selectedTimesheetId.some(item => item.shiftId === row.original.shiftId) ? true : false}>
              </input>
            </div >
          )
        }
  
      }, {
  
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            <small>id</small>
          </button>
        ),
        maxWidth: 75
        ,
        Cell : row =>{
          return (
          <small>
            {
              row.original.isConcelled?
              <del><small>{row.original.shiftId}</small></del>
              :
              <div><small>{row.original.shiftId}</small></div>
            }
          </small>
          )
        }
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Shift Details
          </button>
        ),
        Cell: row => {
          if(row.original.isDeleted){
            console.log(row.original)
          }
          
          var date = row.original.date;
          date = moment(date).format('llll');
          date = date.split(" ");
          return (
            <small
              onClick={() => {
                var arr = [];
                arr = this.state.selectedTimesheetId;
                var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                if (index >= 0) {
                  arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                } else {
                  arr.push(row.original)
                }
                this.setState({ selectedTimesheetId: arr });
              }}
              className={this.state.selectedTimesheetId.some(item => item.shiftId === row.original.shiftId) ? "bg-light" : ''}
            >
              <p style={row.original.isDeleted==true?{"color":"red","width":"100%", "padding":"0"}:{"width":"100%", "padding":"0"}} class="card-text container-fluid text-red">
                {`${row.original.clientName?`Attending ${row.original.clientName} @${row.original.location}`:`A shift @${row.original.location}`} 
                from ${row.original.startTime} to ${row.original.endTime}`}
              </p>
            </small>
          )
        }
  
      },{
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            <small>Date</small>
          </button>
        ),
        maxWidth: 90,
        Cell: row => {
          var date = row.original.date;
          date = moment(date).format('llll');
          date = date.split(" ");
          return (
            row.original.isConcelled?
            <small>
              <del>
                <div>
                  {
                    date[0] + " " + date[1] + " " + date[2] 
                  }
                </div>
              </del>
            </small>
            :
            <small>
              <div>
                {
                  date[0] + " " + date[1] + " " + date[2] 
                }
              </div>
            </small>
          )
        }
  
      }, {
  
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            <small>Duration</small>
          </button>
        ),
        // accessor: 'duration',
        maxWidth: 80,
        Cell : row =>{
          return (
          <small>
            {
            row.original.isConcelled?
              <del>{row.original.duration}</del>
              :
              <div>{row.original.duration}</div>
            }
          </small>
            
          )
        }
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Status
          </button>
        ),
        maxWidth: 230,
        Cell: row => {
  
          return (
            <div>
              {
                row.original.isSendToXeroPayroll?
                <div>XERO SYNCED</div>
                :
                <div>
                  {
                    row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unapproved</span></small></a>
                  }
                  {
                    row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Incomplete</span></small></a>
                  }
                  {
                    row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unconfirmed</span></small></a>
                  }
                </div>
              }
              
            </div>
          )
        }
      }]
  
      return (
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
            {/* <p className="h2">{this.state.selectedStaffName == "" ? 'TimeSheets' : this.state.selectedStaffName}</p> */}
            <div style={{"width":"14%"}}>
              <Select
                value={this.state.lastFilterApplied=='Manager'?this.state.filterSchedulesByManagerValue:{label :'Filter By Manager', value : 0}}
                onChange={(event)=>{
                  var subordinates=[];
                  let  searchInput  = event.value;
                  let filteredData;
                  var pos = 0;
                  this.state.userManagerData.forEach(element=>{
                    pos++;
                    if(event.value==element.managerId){
                      subordinates.push(element.staffManaged);
                    }
                    if(pos==this.state.userManagerData.length){
                      filteredData = this.state.shiftsList.filter(value => {
                        return (
                          subordinates.includes(value.id)
                        );
                      });
                      if(searchInput==0){
                        this.setState({ staffList: this.state.shiftsList, filterSchedulesByManagerValue : event, lastFilterApplied : 'Manager', timesheets :[]});
                      }else{
                        this.setState({ staffList: filteredData, filterSchedulesByManagerValue : event, lastFilterApplied : 'Manager', timesheets :[] });
                      }
                    }
                  })
                  
                }}
                options={this.state.managersSeleectList}
              /> 
            </div>
            <div style={{"width":"15%"}}>
              <Select
                value={this.state.lastFilterApplied=='Location'?this.state.filterStaffByLocationValue:{label :'Filter By Location', value : 0}}
                onChange={(event)=>{
                  var staffs=[];
                  let  searchInput  = event.value;
                  let filteredData;
                  var pos = 0;
                  this.setState({selectedFilterItermId: event.value, address : event.value})
                  this.state.userLocationData.forEach(element=>{
                    pos++;
                    if(event.value==element.propertId){
                      staffs.push(element.staffId);
                    }
                    if(pos==this.state.userLocationData.length){
                      filteredData = this.state.shiftsList.filter(value => {
                        return (
                          staffs.includes(value.id)
                        );
                      });
                      if(searchInput==0){
                        this.setState({ staffList: this.state.shiftsList, filterStaffByLocationValue : event, lastFilterApplied : 'Location', timesheets :[]});
                      }else{
                        this.setState({ staffList: filteredData, filterStaffByLocationValue : event, lastFilterApplied : 'Location', timesheets :[]});
                      }
                    }
                  })
                  
                }}
                options={this.state.locationSelectList}
              /> 
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
  
              {
                GlobalFunctions.extractToken().isXeroConnected==true?
                    <button
                    onClick={() => {
                      this.getXeroPayrollCalendar();
                      this.setState({ pickXeroCalendarDateModal: true });;
                    }}
                    type="button"
                    className="btn btn-sm btn-info mr-2"
                  >
                    Xero Actions
                </button>
                :
                ''
              }
              
              <button
                type="button"
                className="btn btn-sm btn-outline-success mr-2 disabled"
              >
                {parseFloat(this.state.totalTimesheetHours).toFixed(2)} Hours
            </button>
              <div className="btn-group mr-2">
                <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">
                  <span data-feather="calendar"></span>{moment(this.state.weekStart).format('ddd')+', '+moment(this.state.weekStart).format('DD')+' '+moment(this.state.weekStart).format('MMM')} - {moment(this.state.weekEnd).format('ddd')+', '+moment(this.state.weekEnd).format('DD')+' '+moment(this.state.weekEnd).format('MMM')}</button>
                <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
              </div>
  
              <button
                onClick={() =>{this.setState({ ShowAddModal: true })}}
                type="button"
                className="btn btn-sm btn-outline-secondary mr-2"
              >
                &#43; Timesheet
            </button>
              <a href={`${GlobalFunctions.extractToken().API_URL}/timeSheets/excelExport?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
                type="button"
                className="btn btn-sm btn-primary mr-2"
              > &#8645; Export</a>
              <button
                onClick={this.handleApproveTimeSheetsAlert}
                type="button"
                className="btn btn-sm btn-success mr-2">
                Approve
            </button>
            <button
                onClick={this.completedAppointmentAlert_01}
                type="button"
                className="btn btn-sm btn-warning mr-2">
                Complete
            </button>
              <button
                onClick={this.confirmTimesheetAlert}
                type="button"
                className="btn btn-sm btn-info mr-2">
                Confirm
            </button>
              <button
                onClick={this.deleteTimesheetAlert}
                type="button"
                className="btn btn-sm btn-danger mr-2">
                &#10005; Delete
            </button>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-3" >
              <form >
                <input
                  className="form-control ml-1"
                  type="text"
                  placeholder="Search"
                  defaultValue={this.state.searchInput}
                  onChange={this.handleChange}
                  autoFocus />
              </form>
              <div style={{ height: "450px", overflowY: "scroll" }}>
                {
                  this.state.loadingStaff ?
                    <Spinner animation="border" role="status" size="lg" className="text-center" style={{ width: "4rem", height: "4rem", marginLeft: "10rem", marginTop: "8rem" }}>
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    this.state.staffList.map((staff) =>
                      <button
                        value = {staff.id}
                        onClick={(event) => {
                          const staffId = event.target.value;
                          this.state.selectedStaffId= staff.id;
                          this.getTimesheets(staffId);
                          const index = this.state.staffList.findIndex(x => x.id == staff.id);
                          this.setState({ selectedStaffName: staff.staffName });
                        }}
                        className="btn text-white btn-warning btn-block mt-2 ml-1"
                        type="button"
                        key={staff.id}
                        style={{ 'backgroundColor': staff.id == this.state.selectedStaffId ? 'DodgerBlue' : '#f7c133' }}
                      >
                        {`${staff.staffName}`}
                      </button>
                    )
                }
              </div>
            </div>
            <div className="col-md-9">
              <ReactTable
                data={this.state.timesheets}
                loading={this.state.loadingData}
                columns={columns}
                defaultPageSize={10}
              />
            </div>
            {/* ADD SHIFT MODAL */}
            <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
              <form onSubmit={this.handleSubmit}>
                <Modal.Header>
                  <Modal.Title><h5 className="modal-title" >Add shift on {this.state.selectedShiftDate}</h5></Modal.Title>
                  <button type="button" className="close" onClick={()=>{this.setState({ ShowAddModal: false })}}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
  
                    <div className="col-md-12">
  
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4">Who is working?</label>
                          <select
                            required
                            onClick={(event) => {
                              var staffId = event.target.value;
                              this.setState({ selectedStaffId: staffId })
                            }}
                            className="form-control"
                            name="selectedStaffId" >
                            <option>---Select Staff---</option>-
                            {
                              this.state.staffList.map((staff) =>
                                <option
                                  value={staff.id}
                                  key={staff.id}
                                  selected={staff.id==this.state.selectedStaffId}
                                >
                                  {`${staff.staffName}`}
                                </option>
                              )
                            }
                          </select>
  
                        </div>
                      </div>
  
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputAddress">Location</label>
                          <select
                             
                             onChange={(event) => {
                              const index=this.state.companyLocations.findIndex(x=>x.id==event.target.value);
                              this.setState({trackingItemID: this.state.companyLocations[index].trackingItemID})
                              this.handleDataInput(event.target)}
                            }
                             className="form-control" name="address">
                            <option>---Select Location</option>
                            {
                              this.state.companyLocations.map((element) =>
                                <option selected={this.state.selectedFilterItermId==element.id?'selected':''} value={element.id}>{element.name} ({element.address})</option>
                              )
                            }
                          </select>
  
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputAddress">Date</label>
                          <input required type="date" className="form-control" name="date" value={this.state.date} onChange={event => this.handleDataInput(event.target)} />
  
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Start time</label>
                          <input required type="time" className="form-control" value={this.state.startTime} onChange={event => this.handleDataInput(event.target)} name="startTime" />
  
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">End time</label>
                          <input required type="time" className="form-control" value={this.state.endTime} onChange={(event) =>{
                             this.handleDataInput(event.target);
                            //  this.calculateDuration({ startTime: this.state.startTime, endTime: event.target.value });
                             }} name="endTime" />
  
                        </div>
                              
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Breaking in Minutes</label>
                          <input value={this.state.breakMin} type="number" className="form-control" onChange={event => this.handleDataInput(event.target)} name="breakMin" />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Notes</label>
                        <textarea className="form-control" value={this.state.notes} onChange={event => this.handleDataInput(event.target)} name="notes" rows="3"></textarea>
  
                      </div>
  
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {
                    this.state.savingData ?
                      <button
                        className="btn btn-info"
                        type="Submit"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Saving data...
                  </button>
                      :
                      <button
                        className="btn btn-info"
                        type="Submit"
                      >
                        Save shift
                  </button>
                  }
  
                </Modal.Footer>
              </form>
            </Modal>
  
            {/* PICK XERO TIMESHEETS CALENDAR DATE MODAL */}
            <Modal show={this.state.pickXeroCalendarDateModal} onHide={true}  >
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Sync Timesheets   &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                 {/* <a
                    onClick={(event)=>{
                      localStorage.setItem('currentRoute' , window.location.href);
                    }}
                    href={`${GlobalFunctions.extractToken().API_URL}/invoices/connect?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
                    type="button"
                    className="btn btn-sm btn-success mr-2"
                  >
                  Connect
                </a> */}
                <button
                    onClick={() => {
                      this.sychronizeStaffDataWithXero();
                    }}
                    type="button"
                    className="btn btn-sm btn-info mr-2"
                  >
                    Sync Staff
                </button>
            </h5></Modal.Title>
                <button type="button" className="close" onClick={()=>{this.setState({ pickXeroCalendarDateModal: false })}}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <form onSubmit={this.xeroSyncTimesheets}>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                    <div className="form-row">
                    <label htmlFor="inputCity">Do you want to sync for All Staff &nbsp; &nbsp; </label>
                    <div
                      onClick={(event)=>{
                        this.setState({isSendingAllStaffTimeSheets : true});
                      }}
                    ><a href="#"><input checked={this.state.isSendingAllStaffTimeSheets?true:false} type="radio" name="1"></input> Yes</a></div> &nbsp;  &nbsp; 
                    <div  
                      onClick={(event)=>{
                        this.setState({isSendingAllStaffTimeSheets : false});
                      }}
                    ><a href="#"><input checked={!this.state.isSendingAllStaffTimeSheets?true:false}  type="radio" name ="1"></input> No</a></div>
                    </div>
                    <div className="form-row">
                          {
                            !this.state.isSendingAllStaffTimeSheets?
                              <div className="form-group col-md-12">
                                <label htmlFor="inputCity">Selected Staff</label>
                                <input value ={this.state.selectedStaffName}className="form-control"></input>
                            </div>
                            :
                            ''
                          }
                          <div className="form-group col-md-12">
                          <label htmlFor="inputCity">Select Xero Payroll Calendar</label>
                          <select required placeholder="start date" className="form-control" 
                          onChange={(event) => { 
                            const index = this.state.xeroPayrollCalendar.findIndex(x=>x.payrollCalendarID==event.target.value);
                            const daysToAddFromStartDay = this.state.xeroPayrollCalendar[index].calendarType=='FORTNIGHTLY'?13 : 6;
                            const end =moment(this.state.xeroPayrollCalendar[index].startDate).add(daysToAddFromStartDay,'days').format('yyyy-MM-DD')
                            const start = moment(this.state.xeroPayrollCalendar[index].startDate).format('yyyy-MM-DD')
                            this.setState({xeroPayrollCalendarId : event.target.value, payCalendarStart :  start, payCalendarEnd : end});
                          }} 
                          name="xeroPayrollCalendarId" >
                            <option>--- Select Calendar ---</option>
                            {
                              this.state.xeroPayrollCalendar.map((element) =>
                                <option value={element.payrollCalendarID}>{element.name}</option>
                              )
                            }
                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCity">Start date</label>
                          <input required value={this.state.payCalendarStart}  type="date" className="form-control" onChange={event => this.handleDataInput(event.target)} name="payCalendarStart" />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCity">End date</label>
                          <input required value={this.state.payCalendarEnd} type="date" className="form-control" onChange={event => this.handleDataInput(event.target)} name="payCalendarEnd" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-success" type="submit">Send</button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
  
        </div >
      )
    }
  }
  

  export default Timesheets;
  